<template>
  <div class="PlaceOrder">
    <div v-wechat-title="$route.meta.title"></div>
    <van-field
      readonly
      v-model="ord_ent"
      label="订货企业："
      placeholder="订货企业,个人信息页切换企业"
    />
    <van-field
      readonly
      clickable
      label="用户类型："
      :value="user_type"
      placeholder=""
      @click="checkUserType"
    />
    <van-field
      readonly
      clickable
      label="订货日期："
      :value="date_order"
      placeholder=""
      @click="showDate = true"
    />
    <van-field
      readonly
      clickable
      label="订货客户："
      :value="customer_order"
      placeholder="请选择订货客户"
      @click="showCustomer = true"
    />
    <van-field
      readonly
      clickable
      label="收货点："
      :value="product_order"
      placeholder="请选择收货点"
      @click="showProduct = true"
    />
    <van-field
      readonly
      clickable
      label="订货品种："
      :value="var_order"
      placeholder="请选择订货品种"
      @click="showVarieties = true"
    />
    <van-field
      readonly
      clickable
      label="运 输 商 ："
      v-show="isShowShipper"
      :value="shippers"
      placeholder="请选择运输商"
      @click="showShippers = true"
    />
    <van-field
      readonly
      clickable
      label="订货车轴 ："
      v-show="isShowAxle"
      :value="car_alex"
      placeholder="请选择订货车轴"
      @click="showCarAlex = true"
    />
    <van-field
      readonly
      clickable
      label="选择矿点："
      v-show="isShowMines"
      :value="mines"
      placeholder="请选择矿点"
      @click="showMines = true"
    />
    <!-- <van-field readonly clickable label="过磅类型：" v-show="isShowWeighType" :value="weigh_type" placeholder="请选择过磅类型" @click="showWeighType = true"/> -->
    <van-field
      readonly
      clickable
      label="运输方式："
      v-show="isShowTeam"
      :value="trans_mode"
      placeholder="请选择运输方式："
      @click="showTrans = true"
    />
    <!-- <van-field readonly clickable label="选择船号" v-show="isShowHull" :value="hullName" placeholder="请选择船号" @click="showHull = true"/> -->
    <van-field
      v-model="order_num"
      type="number"
      @input="OrdNumInp"
      label="订货数量："
      placeholder="请输入订货数量"
    />
    <van-field name="switch" label="无牌车辆：" v-show="isShowUnVah">
      <template #input>
        <van-switch
          v-model="isCheckUnVah"
          size="30"
          active-color="#29afa5"
          @change="changeUnVah"
        />
      </template>
    </van-field>
    <van-field name="switch" label="固定订单：" v-show="isShowFixedOrder">
      <template #input>
        <van-switch
          v-model="isCheckFixedOrder"
          size="30"
          active-color="#29afa5"
          @change="changeFixedOrd"
        />
      </template>
    </van-field>
    <!-- 有效日期 -->
    <van-field
      readonly
      clickable
      v-show="isCheckFixedOrder == true"
      label="有效日期："
      :value="eff_date"
      placeholder="请选择有效日期"
      @click="showEffDate = true"
    />
    <div class="saitch_box">
      <span class="ag_style">
        <span>农机车牌：</span>
        <van-switch
          style="vertical-align: middle"
          @change="changeAgplate"
          v-model="isCheckAgPlate"
          size="30"
          active-color="#29afa5"
        />
      </span>
      <span v-show="isShowAxle">
        <span>是否退货：</span>
        <van-switch
          style="vertical-align: middle"
          v-model="isCheckReturn"
          size="30"
          active-color="#29afa5"
        />
      </span>
    </div>
    <!-- 车牌号码 -->
    <van-field
      v-show="isShowPlateNum"
      v-model="plate_number"
      :onkeyup="(plate_number = plate_number.replace(/\s+/g, ''))"
      center
      label="车牌号码："
      :readonly="isReadonly"
      placeholder="请输入车牌号码"
      @focus="showKeyWords"
    >
      <template #button>
        <van-button
          round
          plain
          color="#eb2444"
          type="info"
          @click="
            () => {
              showPlateNum = true;
            }
          "
          v-show="isCheckAgPlate === false"
          >选择</van-button
        >
      </template>
    </van-field>
    <van-field
      v-model="driver_name"
      :onkeyup="(driver_name = driver_name.replace(/\s+/g, ''))"
      label="司机姓名："
      placeholder="请输入司机姓名"
    />

    <van-field
      v-model="Id_number"
      :onkeyup="(Id_number = Id_number.replace(/\s+/g, ''))"
      label="证 件 号 ："
      placeholder="请输入证件号"
    />
    <van-field
      v-model="mobile"
      :onkeyup="(mobile = mobile.replace(/\s+/g, ''))"
      label="手机号码："
      placeholder="请输入手机号码"
    />
    <van-field
      v-model="consignee"
      :onkeyup="(consignee = consignee.replace(/\s+/g, ''))"
      label="收 货 人："
      placeholder="请输入收货人"
      v-show="cusTypes === '102'"
    />
    <van-field
      v-show="cusTypes === '102'"
      v-model="consigneePhone"
      :onkeyup="(consigneePhone = consigneePhone.replace(/\s+/g, ''))"
      label="收货人电话"
      placeholder="请输入收货人电话"
    />
    <van-field
      readonly
      clickable
      label="业 务 员："
      :value="salesmanName"
      placeholder=""
      @click="clickSalesman"
    />
    <!-- 000000000000000000000000000000 -->
    <van-field
      v-model="remark"
      label="备注信息："
      placeholder="请输入备注信息"
    />
    <van-button
      size="large"
      loading-text="加载中..."
      :loading="btnLoading"
      @click="saveOrder"
      >下单</van-button
    >
    <!-- jyq---------------选择业务员 -->
    <van-popup v-model="showSalesman" position="bottom">
      <van-picker
        show-toolbar
        :columns="salesmanArray"
        @cancel="onSalesmanCancel"
        @confirm="onSalesmanConfirm"
        :default-index="salesmanIndex"
        value-key="username"
        confirm-button-text="确定"
      />
    </van-popup>
    <!-- 选择用户类型 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="onUserCancel"
        @confirm="onUserConfirm"
        :default-index="0"
        value-key="roleName"
        confirm-button-text="确定"
      />
    </van-popup>
    <!-- 选择订货日期 -->
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :formatter="formatter"
        @confirm="confirmDate"
        @cancel="cancelDate"
        confirm-button-text="确定"
      />
    </van-popup>
    <!-- 选择订货客户 -->
    <van-popup v-model="showCustomer" position="bottom">
      <div class="btn_box">
        <div @click="cancelCus">取消</div>
        <div style="color: #29afa5" @click="confirmCus">确定</div>
      </div>
      <div style="display: flex">
        <van-search
          v-model="search_cus"
          placeholder="输入客商名称进行模糊搜索"
          :clearable="false"
        />
        <div class="search_btn" @click="searchCusOrd">搜索</div>
      </div>
      <van-cell-group>
        <van-cell
          :title="item.name"
          v-for="item in cusOrdList"
          :key="item.id"
          @click="chooseCusOrd(item)"
          :class="{ active: item.id === chooseCus.id }"
        />
      </van-cell-group>
    </van-popup>
    <!-- 选择收货点 -->
    <van-popup v-model="showProduct" position="bottom">
      <div class="btn_box">
        <div @click="cancelProd">取消</div>
        <div style="color: #29afa5" @click="confirmProd">确定</div>
      </div>
      <div style="display: flex">
        <van-search
          v-model="search_prod"
          placeholder="输入项目名称进行模糊搜索"
          :clearable="false"
        />
        <div class="search_btn" @click="searchCusOrd">搜索</div>
      </div>
      <van-cell-group>
        <van-cell
          :title="item.name"
          v-for="item in productList"
          :key="item.id"
          @click="chooseProduct(item)"
          :class="{ active: item.id === chooseProd.id }"
        />
      </van-cell-group>
    </van-popup>
    <!-- 选择订货品种 -->
    <van-popup v-model="showVarieties" position="bottom">
      <div class="btn_box">
        <div @click="cancelMat">取消</div>
        <div style="color: #29afa5" @click="confirmMat">确定</div>
      </div>
      <div style="display: flex">
        <van-search
          v-model="search_var"
          :clearable="false"
          placeholder="输入产品/物料名称进行模糊搜索"
        />
        <div class="search_btn" @click="searchMaterial">搜索</div>
      </div>
      <van-cell-group>
        <van-cell
          :title="item.name"
          v-for="item in materialList"
          :key="item.id"
          @click="chooseMater(item)"
          :class="{ active: item.id === chooseMat.id }"
        />
      </van-cell-group>
    </van-popup>
    <!-- 选择运输商 -->
    <van-popup v-model="showShippers" position="bottom">
      <div class="btn_box">
        <div @click="cancelShip">取消</div>
        <div style="color: #29afa5" @click="confirmShip">确定</div>
      </div>
      <div style="display: flex">
        <van-search
          v-model="search_shippers"
          :clearable="false"
          placeholder="输入运输商名称进行模糊搜索"
        />
        <div class="search_btn" @click="searchShipper">搜索</div>
      </div>
      <van-cell-group>
        <van-cell
          :title="item.name"
          v-for="item in shippersList"
          :key="item.id"
          @click="chooseShipp(item)"
          :class="{ active: item.id === choose_ship.id }"
        />
      </van-cell-group>
    </van-popup>

    <!-- 选择船号 -->
    <!-- <van-popup v-model="showHull" position="bottom" >
      <div class="btn_box">
        <div @click="cancelHull">取消</div>
        <div style="color:#29afa5" @click="confirmHull">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_hull" :clearable=false placeholder="输入船号进行模糊搜索" />
        <div class="search_btn" @click="searchHull">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.shipNo" v-for="item in hullList" :key="item.id" @click="chooseHull(item)" :class="{ active: item.id === choose_hull.id }" />
      </van-cell-group>
    </van-popup> -->

    <!-- 选择订货车轴 -->
    <van-popup v-model="showCarAlex" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns_carAlex"
        @cancel="onCarAlexCancel"
        @confirm="onCarAlexConfirm"
        :default-index="4"
        confirm-button-text="确定"
        value-key="label"
      />
    </van-popup>
    <!-- 选择矿点 -->
    <van-popup v-model="showMines" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns_mines"
        @cancel="onMinesCancel"
        @confirm="onMinesConfirm"
        :default-index="0"
        confirm-button-text="确定"
        value-key="mineral"
      />
    </van-popup>
    <!-- 选择过磅类型 -->
    <!-- <van-popup v-model="showWeighType" position="bottom" >
      <van-picker
        show-toolbar
        :columns="columns_weightype"
        @cancel="onWeightypeCancel"
        @confirm="onWeightypeConfirm"
        :default-index=0
        confirm-button-text="确定"
        value-key="label"
      />
    </van-popup> -->
    <!-- 选择运输方式 -->
    <van-popup v-model="showTrans" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns_trans"
        @cancel="onTransCancel"
        @confirm="onTransConfirm"
        :default-index="0"
        confirm-button-text="确定"
        value-key="label"
      />
    </van-popup>
    <!-- 选择有效日期 -->
    <van-popup v-model="showEffDate" position="bottom">
      <van-datetime-picker
        v-model="currentEffDate"
        type="date"
        :formatter="formatter"
        @confirm="confirmEffDate"
        @cancel="cancelDEffDate"
      />
    </van-popup>
    <!-- 选择车牌号码-->
    <van-popup v-model="showPlateNum" position="bottom">
      <div style="display: flex; border-bottom: 1px solid #d7d6dc">
        <van-search
          v-model="search_licensNum"
          :clearable="false"
          placeholder="输入客商名称/车牌号码进行模糊搜索"
        />
        <div class="search_btn" @click="searchPlateNum">搜索</div>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finished_text"
        @load="onload"
        ref="chepai"
        :immediate-check="false"
        style="height: 220px; overflow: auto"
        :offset="100"
      >
        <div
          class="plate_box"
          v-for="item in car_list"
          :key="item.id"
          @click="chooseAgInfo(item)"
        >
          <span v-show="item.driver !== null">{{ item.driver }}</span>
          <span>{{ item.vehicle }}</span>
          <span>{{ item.idcard }}</span>
        </div>
      </van-list>
    </van-popup>
    <!-- 车牌键盘 -->
    <keyword
      :isShow="keyState"
      @exit="exit"
      @inputchange="getKey"
      :oinp="str"
      @ok="keywordconfirm"
    />
  </div>
</template>

<script>
import keyword from "../../components/keyword";
import wx from "weixin-js-sdk";
import { log } from "console";
export default {
  components: {
    keyword,
  },
  name: "PlaceOrderSh",
  data() {
    return {
      //   jyq 字段添加·-==------------------------------------------
      consignee: "", //收货人
      consigneePhone: "", //收货人电话
      salesmanIndex: 0, //业务员默认选中下标
      salesman: null, // 业务员id
      salesmanName: "暂未选择",
      salesmanArray: [
        {
          username: "暂未选择",
          userId: null,
        },
      ], //业务员data
      showSalesman: false, //业务员弹出框
      // jyq  字段添加 end----------------------------------------
      // 用户类型的显示与隐藏
      showPicker: false,
      // 订货日期的显示与隐藏
      showDate: false,
      // 订货客户的显示与隐藏
      showCustomer: false,
      // 项目的显示与隐藏
      showProduct: false,
      // 选择销售片区的显隐
      showSalesArea: false,
      // 选择工程项目的显隐
      showEngPro: false,
      // 订货品种的显示与隐藏
      showVarieties: false,
      // 运输商的显示与隐藏
      showShippers: false,
      // 船号的显示与隐藏
      // showHull: false,
      // 订货车轴的显隐
      showCarAlex: false,
      // 当切换用户类型时 显隐对应的输入框
      isShowTeam: true,
      // 是否显示运输商
      isShowShipper: true,
      // 是否显示船号
      // isShowHull: false,
      isShowAxle: false,
      // 固定订单的显隐
      isShowFixedOrder: true,
      // 选择矿点的显示与隐藏
      showMines: false,
      // 过磅类型的显隐
      showWeighType: false,
      // 矿点表单的显示与隐藏
      isShowMines: false,
      // 过磅类型的显示与隐藏
      isShowWeighType: false,
      // 运输方式的显示与隐藏
      // 无牌车辆与固定订单 div的显隐
      // isShowAgOrd: true,
      showTrans: false,
      // 有效日期的显示与隐藏
      showEffDate: false,
      // 车牌号码的显示与隐藏
      showPlateNum: false,
      // 輸入框是否只讀
      isReadonly: true,
      // 订货企业
      ord_ent: "",
      // 用户类型
      user_type: "",
      // 订货日期
      date_order: "",
      // 订货客户
      customer_order: "",
      // 项目
      product_order: "",
      // 用户对应的订货客户列表
      cusOrdList: [],
      //客户对应的项目
      productList: [],
      // 销售片区
      sales_area: "",
      // 工程项目
      eng_pro: "",
      // 订货品种
      var_order: "",
      // 订货车轴
      car_alex: "",
      // 过磅类型
      weigh_type: "",
      // 过磅类型id
      weigh_type_id: "",
      // 订货车轴id
      car_alex_id: "",
      // 订货品种id
      varord_id: "",
      // 用户对应的订货品种列表
      materialList: [],
      // 运输商
      shippers: "",
      shippers_id: "",
      // 运输商列表
      shippersList: [],
      // 船号列表
      // hullList: [],
      // 矿点
      mines: "",
      // 矿点id
      mines_id: "",
      // 订货数量
      order_num: "",
      // 运输方式
      trans_mode: "",
      // 运输方式id
      trans_id: "",
      // hullName: '',
      hullId: "",
      // 代理商
      isCheckAgent: false,
      // 物流抢单
      isCheckLogmode: false,
      // 无牌车辆
      isCheckUnVah: false,
      // 无牌车辆的显隐
      isShowUnVah: true,
      // 固定订单
      isCheckFixedOrder: false,
      // 有效日期
      eff_date: "",
      // 农机车牌
      isCheckAgPlate: false,
      // 是否退货
      isCheckReturn: false,
      // 车牌号码
      plate_number: "",
      // 车牌号码的显隐
      isShowPlateNum: true,
      isNoLicensed: "",
      // 司机姓名
      driver_name: "",
      // 证件号
      Id_number: "",
      // 手机号码
      mobile: "",
      // 备注信息
      remark: "",
      // 用户类型数组
      columns: [],
      // 矿点数组
      columns_mines: [],
      // 订货车轴数组
      columns_carAlex: [],
      // 运输方式数组
      columns_trans: [],
      // 订货日期
      currentDate: new Date(),
      // 有效日期
      currentEffDate: new Date(),
      // 订户客户的搜索值
      search_cus: "",
      // 项目搜索值
      search_prod: "",
      // 销售片区搜索值
      search_salesarea: "",
      // 工程项目搜索值
      search_engpro: "",
      // 订货品种的搜索值
      search_var: "",
      // 车牌号码搜索值
      search_licensNum: "",
      // 运输商的搜索值
      search_shippers: "",
      // 船号的搜索值
      // search_hull: '',
      // 车牌键盘的显示与隐藏
      keyState: false,
      // 车牌键盘输入的内容
      str: "",
      cus_id: "",
      tenantId: "",
      // 用户类型
      cusTypes: "",
      // 订货客户id
      clientId: "",
      // 选择的订货客户
      chooseCus: "",
      // 选择的项目
      chooseProd: "",
      // 选择的订货品种
      chooseMat: "",
      // 选择的运输商
      choose_ship: "",
      // 选择的船号
      // choose_hull: '',
      // 车牌列表加载状态
      loading: false,
      finished: false,
      isTime: false,
      // 车辆列表
      car_list: [],
      car_list_copy: [],
      // 上拉加载结束时显示文字
      finished_text: "",
      // 请求下一页
      current_page: 1,
      // 下单按钮的状态
      btnLoading: false,
      // 订单状态
      orderType: "1",
      // 销售车辆/材料车
      vehicleType: "",
      // 订单来源 1 pc端 2 小程序
      orderSource: "2",
      // 产品id
      productId: "",
      purveyId: "",
      SalesArealList: [],
      salesAreaId: "",
      // 选择的销售片区
      chooseSales: "",
      engProList: [],
      engproId: "",
      // 选择的工程项目
      chooseEng: "",
      detailId:"",//计划明细表ID
      // 过磅类型数组
      columns_weightype: [
        {
          label: "先过皮重",
          value: "101",
        },
        {
          label: "先过毛重",
          value: "102",
        },
      ],
    };
  },
  mounted() {},
  created() {
    // this.jyqObj = this.$route.query.obj
    //   ? JSON.parse(this.$route.query.obj)
    //   : {};
    if (this.$route.query.obj) {
      console.log(
        JSON.parse(this.$route.query.obj),
        "JSON.parse(this.$route.query.obj)"
      );
      this.consignee = JSON.parse(this.$route.query.obj).consigneeName
        ? JSON.parse(this.$route.query.obj).consigneeName
        : ""; //收货人
      this.consigneePhone = JSON.parse(this.$route.query.obj).consigneePhone
        ? JSON.parse(this.$route.query.obj).consigneePhone
        : ""; //收货人电话
      this.detailId = JSON.parse(this.$route.query.obj).detailId
            ? JSON.parse(this.$route.query.obj).detailId
            : ""; //计划明细表ID

      // console.log(this.consignee, this.consigneePhone, "733-------------");
      // salesman: null, // 业务员id
      //   salesmanName: "暂未选择",
      // clientId: 20319;
      // consigneeName: null;
      // consigneePhone: null;
      // id: 37;
      // productId: 9498;
      // saleManId: null;
      // saleManName: null;
    }

    // ---------------------------------------------------------------------
    this.tenantId = localStorage.getItem("tenantId");
    this.cus_id = localStorage.getItem("cid");
    // console.log(this.cus_id, "this.cus_id");
    // 根据当前登录的用户id  获取对应的ymtuserid
    this.getYmtUser(this.cus_id);
    // 获取业务员
    this.getsalesmanList();
    // 获取用户信息
    this.getCorp();

    // 获取当前用户对应的客商
    this.getUserTypeList();

    // 获取运输商
    const types = "105";
    this.getShippersList(types);

    // 获取运输方式
    this.getTransportList();

    // 获取用户对应的车辆列表
    this.getCarData();

    // 获取订货车轴
    this.getCarAlex();

    // 获取船号列表
    // this.getHullList()

    this.date_order = this.util.getDate(new Date().getTime());
    this.eff_date = this.util.getNextDate(new Date().getTime());
    this.currentEffDate = new Date(
      this.currentEffDate.setMonth(this.currentEffDate.getMonth() + 1)
    );
    this.weigh_type = this.columns_weightype[0].label;
    this.weigh_type_id = this.columns_weightype[0].value;
  },
  methods: {
    // 根据当前登录的用户id  获取对应的ymtuserid
    async getYmtUser(cid) {
      const { data: res } = await this.$http.get(
        `/ymt/ymtuser/getYmtUser/${cid}`
      );
      console.log(res);
      if (res.code === 0) {
        const data = res.data.data;
        console.log(data);
        this.isShowUnVah = data && data.enableCarPlate === "1" ? true : false;
        this.isShowFixedOrder =
          data && data.enableFixOrder === "1" ? true : false;
        // this.isShowAgOrd = this.isShowUnVah || this.isShowFixedOrder ? true : false
      }
    },

    // 获取用户信息
    async getCorp() {
      const { data: res } = await this.$http.get(
        `/admin/tenant/getListByCurUser/${this.cus_id}`
      );
      console.log(res);
      res.data.forEach((x) => {
        if (x.id == this.tenantId) {
          this.ord_ent = x.name;
        }
      });
    },

    // 获取用户类型列表
    async getUserTypeList() {
      const { data: res } = await this.$http.get(
        `/admin/user/getUserType/${this.tenantId}`
      );
      console.log(res);
      // let types = ''
      if (res.data.length > 0) {
        this.cusTypes =
          res.data[0].roleCode == "YMT_CLIENT"
            ? "102"
            : res.data[0].roleCode == "YMT_SUPPLIER"
            ? "104"
            : "103";
        // this.isShowTeam = res.data[0].roleCode == 'YMT_CLIENT' ? true : false
        if (res.data[0].roleCode == "YMT_CLIENT") {
          this.isShowTeam = true;
          this.isShowMines = false;
          this.isShowAxle = true;
          // this.isShowHull = false
          this.isShowShipper = true;
        } else if (res.data[0].roleCode == "YMT_SUPPLIER") {
          this.isShowTeam = false;
          this.isShowMines = true;
          this.isShowAxle = true;
          // this.isShowHull = true
          this.isShowShipper = true;
        } else if (res.data[0].roleCode == "YMT_MATELS_SUPPLIER") {
          this.isShowAxle = false;
          this.isShowTeam = false;
          this.isShowMines = false;
          // this.isShowWeighType = true
          // this.isShowHull = true
          this.isShowShipper = false;
        }
        const type = this.cusTypes == "102" ? "102" : "104";
        this.getCusOrdList(type, "");
        const material =
          this.cusTypes == "102"
            ? "101"
            : this.cusTypes == "103"
            ? "103"
            : "102";
        this.getMateriaList(material, "");
      }
      this.columns = res.data;
      this.user_type = this.columns[0].roleName;
    },

    // 获取当前用户对应的订货客户列表
    async getCusOrdList(type, key) {
      const url =
        key && key !== ""
          ? "/ymt/company/getListForApplet/" +
            "3" +
            "/" +
            this.cus_id +
            "/" +
            type +
            "/" +
            key
          : "/ymt/company/getListForApplet/" +
            "3" +
            "/" +
            this.cus_id +
            "/" +
            type;
      const { data: res } = await this.$http.get(`${url}`);
      console.log(res);
      if (res.code === 0) {
        this.cusOrdList = res.data;
        if (this.cusOrdList.length && this.$route.query.obj) {
          var obj = JSON.parse(this.$route.query.obj);
          this.clientId = obj.clientId;
          // --------------------------------------
          // console.log(
          //   this.clientId,
          //   "计划量进来的 哈哈哈哈哈------------yq真nb"
          // );
        } else if (this.cusOrdList.length) {
          // console.log(this.clientId, "订单进来的 哈哈哈哈哈------------yq真nb");
          this.customer_order = this.cusOrdList[0].name;
          this.clientId = this.cusOrdList[0].id;
        }
        this.cusOrdList.forEach((item) => {
          if (item.id === this.clientId) {
            this.chooseCus = item;
            this.customer_order = item.name;
            // console.log(
            //   this.chooseCus,
            //   this.customer_order,
            //   this.clientId,
            //   "jyqceshi --------------------"
            // );
          }
        });
        // ------------------------------------------------------------------
        if (this.cusOrdList !== null && this.cusOrdList.length > 0) {
          this.getProductList(this.clientId);
          // 获取销售片区
          this.getSalesArea(this.clientId, "");
          this.getMineralList(this.clientId);
          console.log(this.clientId);
        }
      }
    },
    // 获取项目列表
    async getProductList(id) {
      let that = this;
      const { data: res } = await that.$http.post(
        `ymt/companyproductname/optionsByName`,
        { companyId: id, productName: "" }
      );
      console.log(res);
      if (res.code === 0) {
        this.productList = res.data;
      }
    },
    // 获取订货车轴列表
    async getCarAlex() {
      let that = this;
      const { data: res } = await that.$http.get("/admin/dict/type/axle_type");
      console.log(res);
      if (res.code === 0) {
        that.columns_carAlex = res.data;
        that.columns_carAlex.unshift({ label: "暂不选择", value: "" });
        // jyq修改
        if (this.columns_carAlex.length > 5) {
          this.car_alex = this.columns_carAlex[4].label;
          this.car_alex_id = this.columns_carAlex[4].value;
        } else {
          this.car_alex = this.columns_carAlex[0].label;
          this.car_alex_id = this.columns_carAlex[0].value;
        }
        // jyq
        console.log(that.car_alex_id);
      }
    },

    // // 获取船号列表
    // async getHullList () {
    //   const { data: res } = await this.$http.post('/ymt/ship/options', {
    //       tenantId: this.tenantId,
    //       shipNo: this.search_hull
    //     })
    //   console.log(res)
    //   if (res.code === 0) {
    //     this.hullList = res.data
    //     this.hullList.unshift({shipNo: '暂不选择'})
    //     console.log(this.hullList)
    //     this.hullName = this.hullList.length <= 0 ? '' : this.hullList[0].shipNo
    //     this.hullId = this.hullList.length <= 0 ? null : this.hullList[0].id
    //     this.hullId = this.hullId === undefined ? null : this.hullId
    //     this.hullList.forEach(item => {
    //       if (item.shipNo === this.hullName) {
    //         this.choose_hull = item
    //         console.log(this.choose_hull)
    //       }
    //     })
    //     console.log(this.hullId)
    //   }
    // },

    // 获取销售片区
    async getSalesArea(cid, key) {
      console.log(cid);
      const url =
        key && key !== ""
          ? "/ymt/block/queryblock/" + cid + "/" + key
          : "/ymt/block/queryblock/" + cid;
      const { data: res } = await this.$http.get(`${url}`);
      console.log(res);
      if (res.code === 0) {
        this.SalesArealList = res.data == null ? [] : res.data;
        if (this.SalesArealList.length <= 0) {
          this.sales_area = "";
          this.salesAreaId = null;
          this.engProList = [];
          this.engproId = null;
          this.eng_pro = "";
        } else {
          this.salesAreaId = this.SalesArealList[0].id;
          this.sales_area = this.SalesArealList[0].name;
          this.getEngPro(this.salesAreaId, "");
        }
        this.SalesArealList.forEach((item) => {
          if (item.name === this.sales_area) {
            this.chooseSales = item;
          }
        });
      }
    },
    // 选择销售片区
    chooseSalesArea(val) {
      this.chooseSales = val;
    },

    // 订货客户搜索按钮
    searchCusOrd() {
      const types = this.cusTypes == "102" ? "102" : "104";
      this.getCusOrdList(types, this.search_cus);
    },

    // 选择订货客户
    chooseCusOrd(val) {
      this.chooseCus = val;
    },
    // 选择项目
    chooseProduct(val) {
      this.chooseProd = val;
    },
    // 确认选择订货客户
    confirmCus() {
      this.customer_order = this.chooseCus.name;
      this.clientId = this.chooseCus.id;
      this.getSalesArea(this.clientId, "");
      this.getMineralList(this.clientId);
      this.getProductList(this.chooseCus.id);
      this.showCustomer = false;
    },
    //确认选择项目
    confirmProd() {
      this.product_order = this.chooseProd.productName;
      this.productNameId = this.chooseProd.id;
      this.showProduct = false;
    },
    // 取消选择订货客户
    cancelCus() {
      this.showCustomer = false;
    },
    //取消选择项目
    cancelProd() {
      this.showProduct = false;
    },
    // 搜索销售片区
    searchSalesArea() {
      this.getSalesArea(this.clientId, this.search_salesarea);
    },

    // 确认选择销售片区
    confirmSalesArea() {
      this.sales_area = this.chooseSales.name;
      this.salesAreaId = this.chooseSales.id;
      this.showSalesArea = false;
    },

    // 取消选择销售片区
    cancelSalesArea() {
      this.showSalesArea = false;
    },

    async getEngPro(sid, key) {
      const url =
        key && key !== ""
          ? "/ymt/companyproductname/queryproduct/" + sid + "/" + key
          : "/ymt/companyproductname/queryproduct/" + sid;
      const { data: res } = await this.$http.get(`${url}`);
      console.log(res);
      if (res.code === 0) {
        this.engProList = res.data;
        this.engProList.unshift({ productName: "暂不选择" });
        this.eng_pro = this.engProList[0].productName;
        this.engproId =
          this.engProList[0].id === undefined ? null : this.engProList[0].id;
        this.engProList.forEach((item) => {
          if (item.productName === this.eng_pro) {
            this.chooseEng = item;
          }
        });
      }
    },

    // 搜索工程项目
    searchEngPro() {
      if (this.salesAreaId == null || this.salesAreaId === "") {
        this.engProList = [];
      } else {
        this.getEngPro(this.salesAreaId, this.search_engpro);
      }
    },

    chooseEngPro(val) {
      this.chooseEng = val;
    },

    // 确认选择工程项目
    confirmEngPro() {
      console.log(this.chooseEng);
      this.eng_pro = this.chooseEng.productName;
      this.engproId =
        this.chooseEng.id === undefined ? null : this.chooseEng.id;
      this.showEngPro = false;
    },

    // 取消选择工程羡慕
    cancelEngPro() {
      this.showEngPro = false;
    },

    // 获取当前用户对应的订货品种
    async getMateriaList(type, key) {
      const url =
        key && key !== ""
          ? "/ymt/material/options/" + key
          : "/ymt/material/options/";
      const { data: res } = await this.$http.post(`${url}`, { type: type });
      console.log(res);
      this.materialList = res.data;
      if (this.materialList.length && this.$route.query.obj) {
        var obj = JSON.parse(this.$route.query.obj);
        this.varord_id = obj.productId;
        // --------------------------------------
        // console.log(
        //   this.varord_id,
        //   "计划量进来的 哈哈哈哈哈------------yq真nb"
        // );
      } else if (this.materialList.length) {
        // console.log(this.varord_id, "订单进来的 哈哈哈哈哈------------yq真nb");
        this.var_order = this.materialList[0].name;
        this.varord_id = this.materialList[0].id;
      }
      this.materialList.forEach((item) => {
        if (item.id === this.varord_id) {
          this.chooseMat = item;
          this.var_order = item.name;
          // console.log(
          //   this.chooseMat,
          //   this.var_order,
          //   this.varord_id,
          //   "jyqceshi --------------------"
          // );
        }
      });
    },

    // 订货品种搜索按钮
    searchMaterial() {
      const material =
        this.cusTypes == "102" ? "101" : this.cusTypes == "103" ? "103" : "102";
      this.getMateriaList(material, this.search_var);
    },

    // 选择订货品种
    chooseMater(val) {
      console.log(val);
      this.chooseMat = val;
    },

    // 确认选择订货品种
    confirmMat() {
      this.var_order = this.chooseMat.name;
      this.varord_id =
        this.chooseMat.id === undefined ? null : this.chooseMat.id;
      this.showVarieties = false;
    },

    // 取消选择订货品种
    cancelMat() {
      this.showVarieties = false;
    },

    // 获取矿点列表
    async getMineralList(id) {
      const { data: res } = await this.$http.get(
        `/ymt/company/getMineralBySupplier/${id}`
      );
      console.log(res);
      if (res.code === 0) {
        this.columns_mines = res.data;
        this.columns_mines.unshift({ mineral: "暂不选择" });
        this.mines = this.columns_mines[0].mineral;
        this.mines_id =
          this.columns_mines[0].id === undefined
            ? null
            : this.columns_mines[0].id;
        console.log(this.mines_id);
      }
    },

    // 获取运输商列表
    async getShippersList(type, key) {
      const url =
        key && key !== ""
          ? "/ymt/company/getListForApplet/" +
            "3" +
            "/" +
            this.cus_id +
            "/" +
            type +
            "/" +
            key
          : "/ymt/company/getListForApplet/" +
            "3" +
            "/" +
            this.cus_id +
            "/" +
            type;
      const { data: res } = await this.$http.get(`${url}`);
      if (res.code === 0) {
        this.shippersList = res.data;
        if (this.shippersList.length && this.$route.query.obj) {
          var obj = JSON.parse(this.$route.query.obj);
          this.shippers_id = obj.transitId;
        } else if (this.shippersList.length) {
          this.shippers = this.shippersList[0].name;
          this.shippers_id = this.shippersList[0].id;
        }
        this.shippersList.forEach((item) => {
          if (item.id === this.shippers_id) {
            this.chooseMat = item;
            this.shippers = item.name;
          }
        });
      }
    },

    // 选择运输商
    chooseShipp(val) {
      this.choose_ship = val;
    },

    chooseHull(val) {
      this.choose_hull = val;
    },

    // 确认选择运输商
    confirmShip() {
      this.shippers = this.choose_ship.name;
      this.shippers_id =
        this.choose_ship.id === undefined ? null : this.choose_ship.id;
      this.showShippers = false;
    },

    // 取消选择运输商
    cancelShip() {
      this.showShippers = false;
    },

    // // 确认选择船号
    // confirmHull () {
    //   this.hullName = this.choose_hull.shipNo
    //   this.hullId = this.choose_hull.id === undefined ? null : this.choose_hull.id
    //   this.showHull = false
    // },

    // // 取消选择船号
    // cancelHull () {
    //   this.showHull = false
    // },

    // 获取运输方式列表
    async getTransportList() {
      const { data: res } = await this.$http.get(
        "/admin/dict/type/transport_type"
      );
      console.log(res);
      if (res.code === 0) {
        this.columns_trans = res.data;
        this.columns_trans.unshift({ label: "暂不选择" });
        this.trans_mode =
          this.columns_trans.length <= 0 ? null : this.columns_trans[0].label;
        this.trans_id =
          this.columns_trans.length <= 0 ? null : this.columns_trans[0].value;
        this.trans_id = this.trans_id === undefined ? null : this.trans_id;
      }
    },

    // 运输商搜索框
    searchShipper() {
      const types = "105";
      const key = this.search_shippers;
      this.getShippersList(types, key);
    },

    searchHull() {},

    // 选择用户类型
    checkUserType() {
      this.showPicker = true;
    },

    // 确认选择用户类型
    onUserConfirm(value) {
      console.log(value);
      this.user_type = value.roleName;
      this.showPicker = false;
      if (value.roleCode == "YMT_CLIENT") {
        this.isShowTeam = true;
        this.isShowMines = false;
        this.isShowAxle = true;
        // this.isShowHull = false
        this.isShowShipper = true;
        // jyq修改
        if (this.columns_carAlex.length > 5) {
          this.car_alex = this.columns_carAlex[4].label;
          this.car_alex_id = this.columns_carAlex[4].value;
        } else {
          this.car_alex = this.columns_carAlex[0].label;
          this.car_alex_id = this.columns_carAlex[0].value;
        }
        // jyq
      } else if (value.roleCode == "YMT_SUPPLIER") {
        this.isShowTeam = false;
        this.isShowMines = true;
        this.isShowAxle = true;
        // this.isShowHull = true
        this.isShowShipper = true;
        // jyq修改
        if (this.columns_carAlex.length > 5) {
          this.car_alex = this.columns_carAlex[4].label;
          this.car_alex_id = this.columns_carAlex[4].value;
        } else {
          this.car_alex = this.columns_carAlex[0].label;
          this.car_alex_id = this.columns_carAlex[0].value;
        }
        // // jyq
      } else if (value.roleCode == "YMT_MATELS_SUPPLIER") {
        this.isShowAxle = false;
        this.isShowTeam = false;
        this.isShowMines = false;
        this.isShowWeighType = true;
        // this.isShowHull = true
        this.isShowShipper = false;
        this.car_alex = this.columns_carAlex[0].label;
        this.car_alex_id = this.columns_carAlex[0].value;
      }
      this.cusTypes =
        value.roleCode == "YMT_CLIENT"
          ? "102"
          : value.roleCode == "YMT_SUPPLIER"
          ? "104"
          : "103";
      this.mines_id = this.cusTypes == "102" ? this.mines_id : null;
      this.trans_id = this.cusTypes == "104" ? this.trans_id : null;
      const types = this.cusTypes == "102" ? "102" : "104";
      this.getCusOrdList(types);
      const material =
        this.cusTypes == "102" ? "101" : this.cusTypes == "103" ? "103" : "102";
      this.getMateriaList(material);
    },

    // 取消选择用户类型
    onUserCancel() {
      this.showPicker = false;
    },

    // 确认选择矿点
    onMinesConfirm(value) {
      console.log(value);
      this.mines = value.mineral;
      this.mines_id = value.id;
      this.showMines = false;
    },
    // 取消选择矿点
    onMinesCancel() {
      this.showMines = false;
    },

    // onWeightypeConfirm (value) {
    //   this.weigh_type = value.label
    //   this.weigh_type_id = value.value
    //   this.showWeighType = false
    // },

    onWeightypeCancel() {
      this.showWeighType = false;
    },

    // 无牌车辆切换
    changeUnVah(e) {
      // console.log(e)
      this.isNoLicensed = e ? "1" : "0";
      this.isShowPlateNum = this.isNoLicensed == "1" ? false : true;
      console.log(this.isNoLicensed);
      console.log(this.isShowPlateNum);
    },

    // 固定订单的切换
    changeFixedOrd(e) {
      // 订单类型：固定订单2  临时订单1
      this.orderType = e ? "2" : "1";
    },

    // 订货数量保留两位小数
    OrdNumInp(e) {
      console.log(e);
      if (!/^[+-]?\d*\.?\d{0,2}$/.test(e)) {
        this.$toast({
          message: "订货数量最多保留两位小数",
          duration: 1000,
        });
      }
    },

    // 确认选择运输方式
    onTransConfirm(value) {
      this.trans_mode = value.label;
      this.trans_id = value.value === undefined ? null : value.value;
      this.showTrans = false;
    },

    // 取消选择运输方式
    onTransCancel() {
      this.showTrans = false;
    },

    // 订货日期格式
    formatter(type, val) {
      if (type === "year") {
        this.value1 = val;
        return val + "年";
      }
      if (type === "month") {
        this.value2 = val;
        return val + "月";
      }
      if (type === "day") {
        this.value3 = val;
        return val + "日";
      }
      return val;
    },

    // 确认选择订货日期
    confirmDate(value) {
      console.log(value);
      const selectTime = `${this.value1}年${this.value2}月${this.value3}日`;
      console.log(
        "用户选择的日期",
        new Date(this.currentDate).getTime(),
        selectTime
      );
      this.showDate = false;
      const time = new Date(this.currentDate).toLocaleDateString().split("/");
      if (Number(time[1]) < 10) {
        time[1] = "0" + time[1];
      }
      if (Number(time[2]) < 10) {
        time[2] = "0" + time[2];
      }
      this.date_order = time.join("-");
    },

    // 取消选择订货日期
    cancelDate() {
      this.showDate = false;
    },

    // 确认选择有效日期
    confirmEffDate() {
      const selectTime = `${this.value1}年${this.value2}月${this.value3}日`;
      console.log(
        "用户选择的日期",
        new Date(this.currentDate).getTime(),
        selectTime
      );
      this.showEffDate = false;
      const time = new Date(this.currentEffDate)
        .toLocaleDateString()
        .split("/");
      if (Number(time[1]) < 10) {
        time[1] = "0" + time[1];
      }
      if (Number(time[2]) < 10) {
        time[2] = "0" + time[2];
      }
      this.eff_date = time.join("-");
    },

    // 取消选择有效日期
    cancelDEffDate() {
      this.showEffDate = false;
    },

    onload() {
      this.getCarData();
    },

    onCarAlexConfirm(value) {
      this.car_alex = value.label;
      this.car_alex_id = value.value === undefined ? null : value.value;
      this.showCarAlex = false;
    },

    onCarAlexCancel() {
      this.showCarAlex = false;
    },

    // 获取车辆列表
    async getCarData() {
      console.log(this.isTime, "on");
      if (this.isTime) {
        return;
      }
      setTimeout(() => {
        this.isTime = false;
        console.log(this.isTime, "set");
      }, 500);
      // this.current_page = this.current_page + 1
      console.log(this.loading, "loading");
      this.isTime = true;

      console.log(this.current_page, "this.current_page", this.isTime);
      const { data: res } = await this.$http.get(
        `/ymt/driver/page/${this.cus_id}`,
        {
          params: {
            current: this.current_page,
            size: 100,
          },
        }
      );
      console.log(res);
      if (res.code === 0) {
        // this.car_list = res.data.records
        res.data.records.forEach((item) => this.car_list.push(item));
        res.data.records.forEach((item) => this.car_list_copy.push(item));
        this.loading = false;
        this.current_page = res.data.current + 1;
        if (this.car_list.length >= res.data.total) {
          this.finished_text = "没有更多了";
          this.finished = true;
          return;
        }
        if (this.car_list.length === 0) {
          this.finished_text = "没有更多了";
          this.finished = true;
          return;
        }
      }
    },

    // 选择车牌信息
    chooseAgInfo(val) {
      this.plate_number = val.vehicle || "";
      this.driver_name = val.driver || "";
      this.Id_number = val.idcard || "";
      this.mobile = val.phone || "";
      this.str = val.vehicle || "";
      this.showPlateNum = false;
    },

    // 车牌号搜索
    async searchPlateNum() {
      if (this.search_licensNum !== "") {
        const { data: res } = await this.$http.get(
          `/ymt/driver/getDriverByKey/${this.search_licensNum}`
        );
        console.log(res);
        if (res.code === 0) {
          this.car_list = res.data;
          this.finished_text = this.car_list.length <= 0 ? "" : "没有更多了";
        }
      } else {
        this.car_list = this.car_list_copy;
        this.finished_text = this.car_list.length <= 0 ? "" : "没有更多了";
      }
    },
    // 业务员取消
    onSalesmanCancel() {
      this.showSalesman = false;
    },
    // 业务员确认
    onSalesmanConfirm(value) {
      this.showSalesman = false;
      console.log(value, "业务员确认");
      this.salesman = value.userId;
      this.salesmanName = value.username == "暂未选择" ? null : value.username;
      console.log(this.salesman, this.salesmanName, "业务员确认");
    },
    clickSalesman() {
      this.showSalesman = true;
    },
    // 获取业务员
    async getsalesmanList() {
      const { data: res } = await this.$http.post(
        // `/admin/user/options`,
        `ymt/companyinterface/interface/forwarding/${this.tenantId}/YWY`, ///ymt/companyinterface/interface/forwarding
        { type: "101" }
      );
      if (res.code === 0) {
        this.salesmanArray = [...this.salesmanArray, ...res.data];
        // 计划量跳转obj本地存储
        let obj = this.$route.query.obj;
        // 判断计划量进来的并且有业务员列表 反选
        if (this.salesmanArray.length > 1 && obj) {
          console.log(JSON.parse(obj).salesman, "JSON.parse(obj).salesman");
          this.salesman = JSON.parse(obj).saleManId
            ? JSON.parse(obj).saleManId
            : null;
          this.salesmanName = JSON.parse(obj).saleManName
            ? JSON.parse(obj).saleManName
            : "暂未选择";
          // 反选默认选中下标
          this.salesmanArray.forEach((item, index) => {
            if (this.salesman == item.userId) {
              this.salesmanIndex = index;
            }
          });
        }
      } else {
        console.log("获取业务员接口报错");
      }
    },

    // 下单
    async saveOrder() {
      // debugger
      this.btnLoading = true;
      let warn = "";
      console.log(this.isShowAxle);
      // debugger;
      if (this.clientId === "" || this.clientId == null) {
        warn = "请选择订货客户！";
      } else if (this.varord_id === "" || this.varord_id == null) {
        warn = "请选择订货品种！";
      } else if (this.isShowAxle == true && this.car_alex_id == null) {
        warn = "请选择订货车轴！";
      } else if (this.plate_number === "" && this.isNoLicensed !== "1") {
        warn = "请输入车牌号！";
      } else if (this.Id_number === "") {
        warn = "请输入证件号！";
      } else if (!this.consignee && this.cusTypes == "102") {
        warn = "请输入收货人！";
      } else if (!this.consigneePhone && this.cusTypes == "102") {
        warn = "请选择收货人电话！";
      } else {
        // 有效日期 临时订单没有有效日期
        console.log("点了下单");
        this.eff_date = this.orderType == "2" ? this.eff_date : null;
        // 101 销售车辆 102 材料车 103 材料供应商
        this.vehicleType =
          this.cusTypes == "102"
            ? "101"
            : this.cusTypes == "104"
            ? "102"
            : "103";
        // 是否无牌车
        if (this.isNoLicensed == "1") {
          this.plate_number = "";
        }
        // 如果没有输入订货数量 默认为0
        this.order_num = this.order_num === "" ? 0 : this.order_num;

        console.log(this.vehicleType);

        if (this.vehicleType == "101") {
          // 销售订单011  保存为 产品/客户
          this.clientId = this.varord_id === "" ? null : this.clientId;
          this.productId = this.varord_id === "" ? null : this.varord_id;
          this.hullId = null;
        } else if (this.vehicleType == "102") {
          // 采购订单102 保存为供应商/物料
          this.purveyId = this.clientId === "" ? null : this.clientId;
          this.varord_id = this.varord_id === "" ? null : this.varord_id;
        } else if (this.vehicleType == "103") {
          this.purveyId = this.clientId === "" ? null : this.clientId;
          this.varord_id = this.varord_id === "" ? null : this.varord_id;
        } else {
          // 倒料时去除客商
          this.clientId = null;
          this.hullId = null;
        }

        // // jyq--------------------------加判断----------------------
        // let { data: res } = await this.$http.post(
        //   `ymt/companyinterface/interface/forwarding/92/PDL`,
        //   {
        //     setDate: this.date_order,
        //     // clientId: this.clientId,
        //     // materialId: this.varord_id,
        //     clientId: 4218,
        //     materialId: 7334,
        //     orderNumber: Number(this.order_num),
        //   }
        // );
        // if (res.code === 1) {
        //   this.$dialog({
        //     title: "提示",
        //     message: res.msg,
        //     confirmButtonColor: "#727AA1",
        //     showCancel: false,
        //   }).then(() => {
        //     this.btnLoading = false;
        //   });
        //   return false;
        // }
        // // jyq --------------------------end-----------------------
        this.$http
          .post(
            `/ymt/vehicleorder/saveVehicleOrderForApplet/${this.cus_id}`,
            {
              card: this.Id_number,
              clientId: this.clientId,
              purveyId: this.purveyId,
              corp: this.tenantId,
              driver: this.driver_name,
              idcard: this.Id_number,
              materialId: this.varord_id,
              mineId: this.mines_id,
              mineral: this.mines_id,
              orderNumber: this.order_num,
              orderSource: this.orderSource,
              orderType: this.orderType,
              orderValidDate: this.eff_date,
              productId: this.productId,
              setDate: this.date_order,
              transportType: this.trans_id,
              transporter: this.shippers_id,
              userType: this.cusTypes,
              vehicle: this.plate_number,
              vehicleType: this.vehicleType,
              phone: this.mobile,
              remark: this.remark,
              axle: this.car_alex_id,
              shipNo: this.hullId,
              weightType: this.weigh_type_id,
              projectId: this.productNameId,
              consigneeName: this.cusTypes == "102" ? this.consignee : null,
              detailId:this.detailId,
              consigneePhone:
                this.cusTypes == "102" ? this.consigneePhone : null,
              saleManId: this.cusTypes == "102" ? this.salesman : null,
              saleManName: this.cusTypes == "102" ? this.salesmanName : null,
              planId: this.$route.query.obj
                ? JSON.parse(this.$route.query.obj).id
                : null,
            },
            {
              headers: { Authorization: "Basic cGlnOnBpZw==" },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200 && res.data.code == 0) {
              this.$dialog({
                message: res.data.msg == null ? "下单成功" : res.data.msg,
                theme: "round-button",
              }).then(() => {
                this.btnLoading = false;
                this.eff_date =
                  this.eff_date == null
                    ? this.util.getNextDate(new Date().getTime())
                    : this.eff_date;
                console.log("下单成功");
                wx.miniProgram.navigateTo({
                  url: "/pages/orderList/orderList",
                });
              });
            } else {
              let err_msg = res.data.msg ? res.data.msg : "服务器出了点小差";
              this.btnLoading = false;
              this.$dialog({
                message: err_msg,
                theme: "round-button",
              }).then(() => {
                this.btnLoading = false;
                console.log("下单失败");
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoading = false;
            this.$dialog({
              title: "提示",
              message: err.data.msg,
              confirmButtonColor: "#727AA1",
              showCancel: false,
            }).then(() => {});
          });
      }

      if (warn !== "") {
        this.$dialog({
          title: "提示",
          message: warn,
          confirmButtonColor: "#727AA1",
          showCancel: false,
        }).then(() => {
          this.btnLoading = false;
        });
      }
    },

    changeAgplate(e) {
      if (e) {
        this.isReadonly = false;
        this.keyState = false;
        this.plate_number = "";
      } else {
        this.isReadonly = true;
        this.plate_number = "";
        this.str = "";
      }
    },

    // 车牌号输入框
    showKeyWords() {
      this.keyState = this.isReadonly === false ? false : true;
    },

    // 车牌键盘事件
    exit() {
      this.keyState = false;
      console.log(this.str, "this.str");
      console.log(this.plate_number, "this.plate_number");
    },

    // 车牌号键盘输入的内容
    getKey(val) {
      console.log(this.str);
      if (this.str.length >= 8 && val !== "delete") {
        return false;
      }
      if (val === "delete") {
        this.str = this.str.slice(0, this.str.length - 1);
        this.plate_number = this.str;
      } else {
        this.str += val;
        this.plate_number = this.str;
      }
    },

    // 车牌号键盘点击确认
    keywordconfirm(e) {
      console.log(e);
      this.plate_number = this.str;
      this.keyState = false;
    },
  },
};
</script>

<style lang="less" scoped>
.PlaceOrder {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 0.1rem;
}
.van-cell {
  height: 0.5rem;
  line-height: 0.5rem;
  padding-left: 0.2rem;
  border-bottom: 1px solid #e1e1e1;
  font-size: 0.14rem;
  padding: 0 0.16rem;
}

/deep/ .van-field__label {
  width: 0.88rem;
  margin-right: 0;
}

/deep/ .van-picker__cancel {
  font-size: 0.16rem;
}

/deep/ .van-picker__confirm {
  font-size: 0.16rem;
  color: #29afa5;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.16rem;
  font-size: 0.16rem;
  color: #969799;
  border-bottom: 1px solid #d7d6dc;
}

/deep/ .van-cell-group {
  height: 2rem;
  overflow: scroll;
  font-size: 0.16rem;
  text-align: center;
  border: none;
  border-top: 1px solid #d7d6dc;
  padding: 0.1rem 0;
  .van-cell {
    border-bottom: none;
    padding: 0;
    height: 0.45rem;
    line-height: 0.45rem;
    color: #999;
    // font-weight: 700;
  }
}

/deep/ .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0;
}

/deep/ .van-search {
  width: 3.22rem;
}

/deep/ .van-search .van-cell {
  height: 0.4rem;
  line-height: 0.4rem;
  border-bottom: none;
  padding-left: 0;
}

/deep/ .van-search__content {
  background-color: #fff;
  border: 0.01rem solid #e6e6ea;
  border-radius: 0.2rem;
}

/deep/ .van-search .van-field__control {
  line-height: 0.4rem;
  font-size: 0.14rem;
}

/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  font-size: 0.16rem;
  color: #969799;
}

.search_btn {
  line-height: 0.62rem;
  font-size: 0.16rem;
  color: #29afa5;
}

/deep/ .van-field__control--custom {
  min-height: 0.5rem;
}

/deep/ .van-button--large {
  width: 95%;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  background: linear-gradient(to right, #4bb0ff, #6149f6);
  border-radius: 0.2rem;
  color: #fff;
}

/deep/ .van-field__button .van-button {
  width: 0.65rem;
  height: 0.32rem;
  font-size: 0.14rem;
  vertical-align: middle;
}

/deep/ .van-field__button .van-button:focus {
  background-color: #fff;
}

.plate_box {
  display: flex;
  justify-content: space-between;
  height: 0.45rem;
  line-height: 0.45rem;
  padding: 0 0.2rem;
  border-bottom: 1px solid #d7d6dc;
}

.saitch_box {
  height: 0.5rem;
  line-height: 0.5rem;
  border-bottom: 1px solid #e1e1e1;
  padding-left: 0.16rem;
  font-size: 0.14rem;
  background-color: #fff;
  span {
    color: #646566;
    margin-right: 0.16rem;
  }
}
</style>