<template>
  <div class="transitPage">
    <van-overlay :show="showOverlay">
      <div class="wrapper" @click.stop>
        <van-loading v-show="showLoading" type="spinner" size="48" class="loading-style" color="#1989fa" vertical>正在跳转到车辆自助页面...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: "",
      webSocketUrl: "",
      showLoading: false,
      showOverlay: false,
      roadData: {}
    };
  },

  created() {
    this.code = this.$route.query.code;
    this.$nextTick(function () {
      this.getCodeInfo(this.code);
    });
  },

  destroyed() {
    console.log("离开页面");
    this.socketApi.webSocketClose();
  },

  methods: {
    async getCodeInfo(code) {
      this.showLoading = true;
      this.showOverlay = true;
      const { data: res } = await this.$http.get("ymt/laneregister/page", {
        params: {
          qrCode: code,
        },
      });
      
      setTimeout(()=>{
        console.log(res);
        this.showLoading = false;
        this.showOverlay = false;

        if (res.code == 0 && res.data.records.length > 0) {
          this.roadData = res.data.records[0]

          //建立socket连接
          const websocketIP = "ws://" + this.roadData.webSocket
          this.socketApi.initWebSocket(websocketIP).then((res) => {
          console.log(res, 'res')
          });
          this.websocketSend({});
        } else {
          this.$toast({
            message: '未配置车道信息',
            duration: 1000
          })
        }
      },1000)
      
    },

    // 接收socket回调函数返回数据的方法
    getConfigResult(res) {
      console.log(res, "硬件数据"); //硬件返回的数据
      if (Object.prototype.toString.call(res) == '[object Array]') {
         let obj = res.find(x => x.place == this.roadData.placeId)
          console.log(obj)
          if (obj != undefined) {
            // 自动
            if (obj.isAuto == 1) {
              this.$router.push({ path: '/selfCar'})
              sessionStorage.setItem('roadData', JSON.stringify(this.roadData))
            } else if (obj.isAuto == 0) {
              this.$router.push({ path: '/selfCarSp'})
              sessionStorage.setItem('roadData', JSON.stringify(this.roadData))
            }
          }
      } else if (Object.prototype.toString.call(res) == '[object Object]') {
          // 自动
          if (res.isAuto == 1) {
            this.$router.push({ path: '/selfCar'})
            sessionStorage.setItem('roadData', JSON.stringify(this.roadData))
          } else if (res.isAuto == 0) {
            this.$router.push({ path: '/selfCarSp'})
            sessionStorage.setItem('roadData', JSON.stringify(this.roadData))
          }
      } else {
        this.socketApi.webSocketClose();
      }
     
    },

    websocketSend(data) {
      //data为要发送的数据，this.getConfigResult为回调函数，用于在此页面接收socket返回的数据。
      this.socketApi.sendSock(data, this.getConfigResult);
    },

  },
};
</script>

<style lang="less" scoped>
.transitPage {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  .loading-style {
    width: 100%;
    margin: 2.5rem 0 0 0;
    text-align: center;
  }
}
</style>