<template>
    <div class="report_box">
      <div class="report">
        <div v-wechat-title='$route.meta.title'></div>
        <ve-table
            fixed-header
        border-y
        :columns="columns"
        :table-data="tableData"
        row-key-field-name="rowKey"
        :cell-style-option="cellStyleOption"
        :row-style-option="rowStyleOption"
        :footer-data="footerData"
        :cell-span-option="cellSpanOption"
        />
    </div>
    <van-button size="large" @click="search">
      <ve-icon name="filter" :size='60' />
    </van-button>
    </div>
</template>

<script>
export default {
    name: 'ProductSalesSummary',
    data() {
      return {
        cellSpanOption: {
          footerCellSpan: this.footerCellSpan,
        },
        virtualScrollOption: {
        // 是否开启
            enable: true,
        },
        rowStyleOption: {
          stripe: true,
        },
        cellStyleOption: {
          headerCellClass: ({ column, rowIndex }) => {
            if (rowIndex === 0) {
              return "table-header-cell-class";
            }
          },
          footerCellClass: ({ row, column, rowIndex }) => {
            if (column.field == "customer_code") {
                return "table-footer-cell-class1";
            }

            if (column.field == "num") {
                return "table-footer-cell-class1";
            }

            if (column.field == "price") {
                return "table-footer-cell-class1";
            }

            if (column.field == "money") {
                return "table-footer-cell-class1";
            }

          },
        },
        columns: [
          { field: "customer_code",
            key: "2",
            title: "客户编码",
            align: "center",
          },
          { field: "customer_name",
            key: "3",
            title: "客户名称",
            align: "center"
          },
          { field: "material",
            key: "4",
            title: "物料",
            align: "center"
          },
          { field: "num",
            key: "5",
            title: "数量",
            align: "center"
          },
          { field: "price", 
            key: "6",
            title: "单价",
            align: "center"
          },
          { field: "money",
            key: "7",
            title: "金额",
            align: "center"
          },
        ],
        tableData: [
          {
            customer_code: "121213131",
            customer_name: "眉山海螺新材料科技有限公司",
            material: "防风服",
            num: "7",
            price: "10.20",
            money: "390",
          },
          {
            customer_code: "121213131",
            customer_name: "眉山海螺新材料科技有限公司",
            material: "防风服",
            num: "7",
            price: "10.20",
            money: "390",
          },
          {
            customer_code: "121213131",
            customer_name: "眉山海螺新材料科技有限公司",
            material: "防风服",
            num: "7",
            price: "10.20",
            money: "390",
          },
        ],
        footerData: [{
            customer_code: "合计",
            customer_name: "",
            material: "",
            num: "22",
            price: "100",
            money: "3920",
        }],
        cus_id: '',
        tenantId: '',
        token: ''
      };
    },

    created () {
      this.cus_id = localStorage.getItem('cid')
      this.tenantId = localStorage.getItem('tenantId')
      this.token = localStorage.getItem('token')
    },

    methods: {

        footerCellSpan({ row, column, rowIndex }) {
        if (column.field === "customer_code") {
            return {
              rowspan: 1,
              colspan: 3,
            };
          }
          // does not need to be rendered
         else if (column.field === "customer_name") {
            return {
              rowspan: 0,
              colspan: 0,
            };
          } else if (column.field === "material") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        }   
      },
      search () {
        this.$router.push({ path: '/psssearch', query: { tid: this.tenantId, token: this.token, cid: this.cus_id } })
      }
    }
}
</script>

<style lang="less" scoped>
.report_box {
  width: 100%;
}

.report {
    width: 7.5rem;
    padding-bottom: 2rem;
}

/deep/ .table-header-cell-class {
    background: #29afa5 !important;
    color: #fff !important;
  }

   /deep/ .table-footer-cell-class1 {
    background: #91d5ff !important;
    color: #fff !important;
  }

 /deep/ .table-footer-cell-class2 {
    background: orange !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class3 {
    background: #ff5457 !important;
    color: #fff !important;
  }

  /deep/ .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td, .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
    padding: 0;
  }

  /deep/ .van-button--large {
  width: 100%;
  // width: 7.5rem;
  height: .8rem;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: #29afa5;
  // border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}
</style>