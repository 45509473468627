<template>
  <div class="hello-ezuikit-js">
    <div id="video-container" style="width:100%;height:300px"></div>
    <div class="father-box">
      <div></div>
      <div class="clarity-box">
        <div v-for="(item, index) in clarityList" :key="index" @click="clickCla(item.id)" :class="{bgcStyle: claId == item.id}">{{item.value}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";

export default {
  props: {
    msg: String,
    parent: Object
  },
  data() {
    return {
      roadData: '',
      videoUrl: "",
      accessToken: '',
      claId: 0,
      clarityList: [
        {
          id: 0,
          value: "流畅",
        },
        {
          id: 1,
          value: "高清",
        },
      ]
    };
  },
  watch: {},
  mounted() {
    this.roadData = JSON.parse(sessionStorage.getItem('roadData'))
    this.videoUrl = this.roadData.rtmp.replace(/hd./, '')
    this.getAccessToken();
  },
  created() {
    // console.log(this.videoForm);
    // setTimeout(()=>{
    //   player.stop(); // 方法调用示例，10秒后关闭视频
    // },10000)
  },

  methods: {
    async getAccessToken() {
      const { data: res } = await this.$http.post(
        "/lapp/token/get?appKey=604bb3df823543258fb51a1395845342&appSecret=8937282420cd9ff8f9e540ca01ae443f",
        {
          headers: { "content-type": "application/x-www-form-urlencoded" },
        }
      );

      console.log(res);
      if (res.code == 200) {
        this.accessToken = res.data.accessToken;
        this.initVideo()
      }
    },

    initVideo () {
      var player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "video-container",
        accessToken: this.accessToken,
        url: this.videoUrl,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        // width: 375,
        height: 300,
      });
      console.log("player", player);
    },

    
    clickCla(id) {
      this.claId = id;
      console.log(id);

      if (id == 0) {
        this.videoUrl = this.roadData.rtmp.replace(/hd./, '')
      } else {
        this.videoUrl = this.roadData.rtmp
      }

      console.log(this.videoUrl);

      this.initVideo()
    },
  },
};
</script>

<style lang="less">
.video-containerdraw-window {
  border: none !important;
}

.loading-container {
  font-size: 14px;
}

.hello-ezuikit-js #video-container-wrap {
  width: 100% !important;
}

.father-box {
  display: flex;
  justify-content: space-between;
  margin-right: 0.1rem;
}

.clarity-box {
  display: flex;
  width: 1rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border: 0.01rem solid #e6e6e6;
  margin-top: 0.05rem;

  div {
    width: 50%;
    text-align: center;
  }

  :first-child {
    border-right: 0.01rem solid #e6e6e6;
  }
}

.bgcStyle {
  background-color: #5d71a7 !important;
  color: #fff !important;
}
</style>
