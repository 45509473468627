<template>
  <div class="selfCar">
    <div v-wechat-title='$route.meta.title'></div>

    <EZUIKitJs />

    <div class="vehicle-box">
      <div class="vehicle-box-title">车辆信息</div>
      <div class="vehicle-box-info">
        <div class="vehicle">{{vehicle}}</div>
        <div><van-tag type="success" size="large">自动装车</van-tag></div>
      </div>
    </div>

    <div class="car-info-box" v-if="isLine == 1">
      <div class="car-status-text">已到达起点</div>
      <div class="sure-box">
        <div class="sure-box-text">距离起点 <span class="sure-box-num">{{distance}}</span> 米</div>
        <van-button round color="linear-gradient(to right, #ff6034, #ee0a24)" @click="surePoint">
          确定起点
        </van-button>
      </div>
    </div>

    <div class="car-info-box" v-else>
      <div class="car-status-text2">未到达地点</div>
      <div class="sure-box-text" style="text-align:center;margin-bottom:.1rem">距离地点 <span class="sure-box-num">{{distance}}</span> 米</div>
    </div>
  </div>
</template>

<script>
import EZUIKitJs from '../../components/EZUIKitJs.vue'
export default {
  name: 'selfCar',
  components: {
    EZUIKitJs
  },
  data () {
    return {
      roadData: {},
      vehicle: '',
      isLine: '',
      distance: 0
    }
  },

  mounted(){ //防止页面后退
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
    　　history.pushState(null, null, document.URL);
　　});
  },

  destroyed() {
    console.log("离开页面");
    this.socketApi.webSocketClose();
  },

  created () {
    this.roadData = JSON.parse(sessionStorage.getItem('roadData'))

    this.setWebsocket(this.roadData.webSocket)
  },

  methods: {
    //建立socket连接
    setWebsocket (url) {
      const websocketIP = "ws://" + url
      this.socketApi.initWebSocket(websocketIP).then((res) => {
      console.log(res, 'res')
      });
      this.websocketSend({});
    },
    
    // 接收socket回调函数返回数据的方法
    getConfigResult(res) {
      console.log(res, "硬件数据"); //硬件返回的数据
      if (res.code == 0) {
        this.$toast({
          message: res.msg,
          duration: 2000
        })
      } else if (res.code == 1) {
        this.$toast({
          message: res.msg,
          duration: 2000,
        });
      } else if (Object.prototype.toString.call(res) == '[object Array]') {
         let obj = res.find(x => x.place == this.roadData.placeId)
          console.log(obj)
          this.vehicle = obj.vehicle
          this.isLine = obj.isLine
          this.distance = obj.distance
      } else if (Object.prototype.toString.call(res) == '[object Object]') {
        this.vehicle = res.vehicle
        this.isLine = res.isLine
        this.distance = res.distance
      } else {
        this.socketApi.webSocketClose();
      }
     
    },

    websocketSend(data) {
      //data为要发送的数据，this.getConfigResult为回调函数，用于在此页面接收socket返回的数据。
      this.socketApi.sendSock(data, this.getConfigResult);
    },

    surePoint () {
      let data = {
        place: this.roadData.placeId,
        type: 'start'
      }
      this.websocketSend(data)
    }
  }
}
</script>


<style lang="less" scoped>
.selfCar {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  .vehicle-box {
    margin: .1rem;
    box-shadow: 0 .1rem .2rem 0 #e6e6e6;

    .vehicle {
      font-weight: bold;
      font-size: .16rem;
    }
  }

  .vehicle-box-info {
    display: flex;
    justify-content: space-between;
    border: .01rem solid #dde3f1;
    height: .4rem;
    line-height: .4rem;
    padding: 0 .15rem;
    background-color: #fff;
  }

  .vehicle-box-title {
    height: .32rem;
    line-height: .32rem;
    background-color: #3a3f56;
    color: #fff;
    font-weight: bold;
    padding-left: .1rem;
  }

  .car-info-box {
    margin: .1rem .1rem;
    // border: .01rem solid #e6e6e6;
    padding: .1rem .15rem;
    background-color: #3a3f56;
    color: #fff;
    box-shadow: 0 .1rem .2rem 0 #999595;

    .car-status-text {
      font-size: .28rem;
      font-weight: bold;
      color: #29afa5;
      // text-align: center;
    }

    .car-status-text2 {
      font-size: .28rem;
      font-weight: bold;
      color: #f00;
      text-align: center;
      padding: .1rem 0;
    }

    .sure-box {
      display: flex;
      justify-content: space-between;
      line-height: .4rem;
      margin: .1rem 0;
    }

    .sure-box-text{
      font-size: .18rem;
    }

    .sure-box-num{
      font-size: .24rem;
      font-weight: bold;
      color: #E6A23C;
    }
  }
}

.van-button--normal {
  font-size: .16rem;
}

</style>