<template>
  <div class="service">
    <div v-wechat-title='$route.meta.title'></div>
    <div class="headText">填写售后信息</div>

    <van-form @submit="onSubmit" label-width="4.5em">
      <van-field v-model.trim="form.submitUser" label="提交人:" required placeholder="请填写提交人姓名" />
      <van-field v-model.trim="form.phone" label="手机号:" required placeholder="请填写提交人联系方式" />
      <van-field v-model.trim="form.content" label="售后内容:" required type="textarea" autosize rows=5 placeholder="请填写售后内容" class="big-inp" />
      <div style="margin: 16px;">
        <van-button round block type="info" loading-text="提交中..." :loading="btnLoading" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        retrospectId: "",
        submitUser: "",
        phone: "",
        content: "",
      },
      btnLoading: false,
    };
  },

  mounted() {},

  created() {
    this.form.retrospectId = this.$route.query.id;
  },

  methods: {
    async onSubmit() {
      const _this = this;

      if (this.form.submitUser == "") {
        return this.$toast({ message: "请填写提交人姓名" });
      }

      if (this.form.phone == "") {
        return this.$toast({ message: "请填写提交人联系方式" });
      }

      if (this.form.content == "") {
        return this.$toast({ message: "请填写售后内容" });
      }

      this.btnLoading = true;
      const { data: res } = await this.$http.post(
        "/zs/retrospect/aftersale",
        this.form
      );

      console.log(res);
      this.btnLoading = false;
      if (res.code == 0) {
        this.$toast({ message: "提交成功" });
        setTimeout(function () {
          _this.$router.go(-1);
        }, 1000);
      } else {
        this.$toast({ message: res.msg || '提交失败' });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  margin: 0.1rem;

  .headText {
    font-size: 0.18rem;
    font-weight: bold;
    margin: 0.2rem 0 0.1rem 0.1rem;
  }
}

/deep/ .big-inp .van-field__control {
  background-color: #fafafa;
}
</style>