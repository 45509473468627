<template>
    <div class="report_box">
      <div class="report">
        <div v-wechat-title='weChatTitle'></div>
        <div class="money-box">
            <div style="margin-right: .6rem">上期余额：<span style="color: orange">¥ {{lastMoney}}</span></div>
            <div>期末余额：<span style="color: #ff5457">¥ {{termEndMoney}}</span></div>
        </div>
        <ve-table
          fixed-header
          border-y
          :columns="columns"
          :table-data="tableData"
          row-key-field-name="rowKey"
          :cell-style-option="cellStyleOption"
          :row-style-option="rowStyleOption"
          :cell-span-option="cellSpanOption"
        />
    </div>  <!-- :footer-data="footerData" -->
    <van-button size="large" @click="search">
      <ve-icon name="filter" :size='48' class="" />
    </van-button>
    </div>
</template>

<script>
export default {
    name: 'CustomerReport',
    data() {
      return {
        weChatTitle:'报表',
        cellSpanOption: {
          footerCellSpan: this.footerCellSpan,
        },
        virtualScrollOption: {
        // 是否开启
            enable: true,
        },
        rowStyleOption: {
          stripe: true,
        },
        cellStyleOption: {
          headerCellClass: ({ column, rowIndex }) => {
            if (rowIndex === 0) {
              return "table-header-cell-class";
            }
          },
          footerCellClass: ({ row, column, rowIndex }) => {
            if (column.field == "num") {
                return "table-footer-cell-class1";
            }

            if (column.field == "invoice_amount") {
                return "table-footer-cell-class1";
            }

            if (column.field == "payee_amount") {
                return "table-footer-cell-class1";
            }

            if (column.field == "customer_name") {
                return "table-footer-cell-class1";
            }

            if (column.field == "balance") {
                return "table-footer-cell-class1";
            }

            if (column.field == "wagon_num") {
                return "table-footer-cell-class1";
            }

            if (column.field == "remark") {
                return "table-footer-cell-class1";
            }

            if (column.field == "price") {
                return "table-footer-cell-class1";
            }
          },
        },
        columns: [
          // { field: "customer_name",
          //   key: "2",
          //   title: "客户名称",
          //   align: "center",
          // },
          // { field: "type",
          //   key: "3",
          //   title: "类型",
          //   align: "center",
          // },
          // { field: "document_num",
          //   key: "4",
          //   title: "单据号",
          //   align: "center",
          // },
          // { field: "product_name",
          //   key: "5",
          //   title: "产品名称",
          //   align: "center"
          // },
          // { field: "num",
          //   key: "6",
          //   title: "数量",
          //   align: "center"
          // },
          // { field: "price",
          //   key: "7",
          //   title: "单价",
          //   align: "center"
          // },
          // { field: "invoice_amount",
          //   key: "8",
          //   title: "发货单金额",
          //   align: "center"
          // },
          // { field: "payee_amount",
          //   key: "9",
          //   title: "收款金额",
          //   align: "center"
          // },
          // { field: "balance", 
          //   key: "10",
          //   title: "余额",
          //   align: "center"
          // },
          // { field: "wagon_num",
          //   key: "11",
          //   title: "车号",
          //   align: "center"
          // },
          // { field: "remark",
          //   key: "12",
          //   title: "备注",
          //   align: "center"
          // },
        ],
        tableData: [
          // {
          //   customer_name: "眉山海螺新材料科技有限公司",
          //   type: "类型一",
          //   document_num: "13J776342222123",
          //   product_name: "助磨剂",
          //   num: "11",
          //   price: "0.02",
          //   invoice_amount: "1001",
          //   payee_amount: "7288",
          //   balance: "100",
          //   wagon_num: "浙AB66666",
          //   remark: "我是备注",
          // },
          // {
          //   customer_name: "眉山海螺新材料科技有限公司",
          //   type: "类型一",
          //   document_num: "13J776342222123",
          //   product_name: "助磨剂",
          //   num: "11",
          //   price: "0.02",
          //   invoice_amount: "1001",
          //   payee_amount: "7288",
          //   balance: "100",
          //   wagon_num: "浙AB66666",
          //   remark: "我是备注",
          // },
          // {
          //   customer_name: "眉山海螺新材料科技有限公司",
          //   type: "类型一",
          //   document_num: "13J776342222123",
          //   product_name: "助磨剂",
          //   num: "11",
          //   price: "0.02",
          //   invoice_amount: "1001",
          //   payee_amount: "7288",
          //   balance: "100",
          //   wagon_num: "浙AB66666",
          //   remark: "我是备注",
          // },
          // {
          //   customer_name: "眉山海螺新材料科技有限公司",
          //   type: "类型一",
          //   document_num: "13J776342222123",
          //   product_name: "助磨剂",
          //   num: "11",
          //   price: "0.02",
          //   invoice_amount: "1001",
          //   payee_amount: "7288",
          //   balance: "100",
          //   wagon_num: "浙AB66666",
          //   remark: "我是备注",
          // },
          // {
          //   customer_name: "眉山海螺新材料科技有限公司",
          //   type: "类型一",
          //   document_num: "13J776342222123",
          //   product_name: "助磨剂",
          //   num: "11",
          //   price: "0.02",
          //   invoice_amount: "1001",
          //   payee_amount: "7288",
          //   balance: "100",
          //   wagon_num: "浙AB66666",
          //   remark: "我是备注",
          // },
        ],
        footerData: [{
            // customer_name: "小计",
            // type: "",
            // document_num: "",
            // product_name: "",
            // num: "0",
            // price: "",
            // invoice_amount: "0",
            // payee_amount: "0",
            // balance: "",
            // wagon_num: "",
            // remark: "",
        }],
        cus_id: '',
        tenantId: '',
        token: '',
        reportType: '',
        lastMoney: '',
        termEndMoney: ''
      };
    },

    created () {
      this.cus_id = localStorage.getItem('cid')
      this.tenantId = localStorage.getItem('tenantId')
      this.token = localStorage.getItem('token')
      this.reportType = this.$route.query.reportType
      this.getTableHeader()
      this.tableData = JSON.parse(localStorage.getItem('reportTable')) || []
      this.lastMoney = localStorage.getItem('priorPeriod') || ''
      this.termEndMoney = localStorage.getItem('termEnd') || ''
    },

    destroyed () {
      console.log('leave')
      localStorage.removeItem('reportTable')
      localStorage.removeItem('priorPeriod')
      localStorage.removeItem('termEnd')
    },

    methods: {
      async getTableHeader () {
        // const { data: res } = await this.$http.get('/daemon/api/report/columns?reportType=QUERT_XS_REPORTSUM')
        const { data: res } = await this.$http.get('/daemon/api/report/columns?reportType=' + this.reportType)
        console.log(res)
        if (res.code == 0) {
          this.weChatTitle = res.data.reportName
          this.columns = res.data.colums
        }
      },

      search () {
        this.$router.push({ path: '/cdsearch', query: { tid: this.tenantId, token: this.token, cid: this.cus_id } })
      },

      footerCellSpan({ row, column, rowIndex }) {
        if (column.field === "customer_name") {
            return {
              rowspan: 1,
              colspan: 4,
            };
          }
          // does not need to be rendered
          else if (column.field === "type") {
            return {
              rowspan: 0,
              colspan: 0,
            };
          } else if (column.field === "document_num") {
            return {
              rowspan: 0,
              colspan: 0,
            };
          } else if (column.field === "product_name") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        }   
      },
    }
}
</script>

<style lang="less" scoped>
.report_box {
  width: 100%;
}

.report {
    width: 16rem;
    padding-bottom: 2rem;
}

/deep/ .table-header-cell-class {
    background: #29afa5 !important;
    color: #fff !important;
  }

 /deep/ .table-footer-cell-class1 {
    background: #91d5ff !important;
    color: #fff !important;
  }

 /deep/ .table-footer-cell-class2 {
    background: orange !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class3 {
    background: #ff5457 !important;
    color: #fff !important;
  }

  /deep/ .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td, .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
    padding: 0;
  }

  /deep/ .van-button--large {
  width: 100%;
  // width: 7.5rem;
  height: .8rem;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: #29afa5;
  // border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

// .search_btn {
//   position: fixed;
//   bottom: 3rem;
//   right: .5rem;
// }

.money-box {
    display: flex;
    margin: .2rem;
    font-size: .28rem;
}
</style>