<template>
    <div class="report_box">
      <div class="report">
        <div v-wechat-title='$route.meta.title'></div>
        <ve-table
          fixed-header
          border-y
          :columns="columns"
          :table-data="tableData"
          row-key-field-name="rowKey"
          :cell-style-option="cellStyleOption"
          :row-style-option="rowStyleOption"
          :footer-data="footerData"
          :cell-span-option="cellSpanOption"
        />
    </div>
    <van-button size="large" @click="search">
      <ve-icon name="filter" :size='48' class="" />
    </van-button>
    </div>
</template>

<script>
export default {
    name: 'ProductSalesSchedule',
    data() {
      return {
        cellSpanOption: {
          footerCellSpan: this.footerCellSpan,
        },
        virtualScrollOption: {
        // 是否开启
            enable: true,
        },
        rowStyleOption: {
          stripe: true,
        },
        cellStyleOption: {
          headerCellClass: ({ column, rowIndex }) => {
            if (rowIndex === 0) {
              return "table-header-cell-class";
            }
          },

          footerCellClass: ({ row, column, rowIndex }) => {
            if (column.field == "delivery_date") {
                return "table-footer-cell-class1";
            }

            if (column.field == "num") {
                return "table-footer-cell-class1";
            }

            if (column.field == "price") {
                return "table-footer-cell-class1";
            }

            if (column.field == "money") {
                return "table-footer-cell-class1";
            }

            if (column.field == "car_num") {
                return "table-footer-cell-class1";
            }

            if (column.field == "forwarder") {
                return "table-footer-cell-class1";
            }

            if (column.field == "remark") {
                return "table-footer-cell-class1";
            }

          },
        },
        columns: [
          {
            field: "",
            key: "1",
            title: "序号",
            align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return ++rowIndex;
            },
        },
          { field: "delivery_date",
            key: "2",
            title: "发货日期",
            align: "center",
          },
          { field: "consign_num",
            key: "3",
            title: "发货单单号",
            align: "center",
          },
          { field: "customer_code",
            key: "4",
            title: "客户编码",
            align: "center",
          },
          { field: "customer",
            key: "5",
            title: "客户",
            align: "center"
          },
          { field: "concrete_num",
            key: "6",
            title: "水泥编号",
            align: "center"
          },
          { field: "product_name",
            key: "7",
            title: "产品名称",
            align: "center"
          },
          { field: "num",
            key: "8",
            title: "数量",
            align: "center"
          },
          { field: "price",
            key: "9",
            title: "单价",
            align: "center"
          },
          { field: "money", 
            key: "10",
            title: "金额(元)",
            align: "center"
          },
          { field: "car_num",
            key: "11",
            title: "车号",
            align: "center"
          },
          { field: "forwarder",
            key: "12",
            title: "运输商",
            align: "center"
          },
          { field: "remark",
            key: "13",
            title: "备注",
            align: "center"
          }
        ],
        tableData: [
          {
            delivery_date: "2021-05-20",
            consign_num: "121313112121",
            customer_code: "111d2113",
            customer: "客户1",
            concrete_num: "3113441rrr12",
            product_name: "产品名称1",
            num: "11",
            price: "7.88",
            money: "102.00",
            car_num: "浙A666666",
            forwarder: "运输商1213",
            remark: "我是备注121"
          },
          {
            delivery_date: "2021-05-20",
            consign_num: "121313112121",
            customer_code: "111d2113",
            customer: "客户1",
            concrete_num: "3113441rrr12",
            product_name: "产品名称1",
            num: "11",
            price: "7.88",
            money: "102.00",
            car_num: "浙A666666",
            forwarder: "运输商1213",
            remark: "我是备注121"
          },
          {
            delivery_date: "2021-05-20",
            consign_num: "121313112121",
            customer_code: "111d2113",
            customer: "客户1",
            concrete_num: "3113441rrr12",
            product_name: "产品名称1",
            num: "11",
            price: "7.88",
            money: "102.00",
            car_num: "浙A666666",
            forwarder: "运输商1213",
            remark: "我是备注121"
          },
          {
            delivery_date: "2021-05-20",
            consign_num: "121313112121",
            customer_code: "111d2113",
            customer: "客户1",
            concrete_num: "3113441rrr12",
            product_name: "产品名称1",
            num: "11",
            price: "7.88",
            money: "102.00",
            car_num: "浙A666666",
            forwarder: "运输商1213",
            remark: "我是备注121"
          },
          {
            delivery_date: "2021-05-20",
            consign_num: "121313112121",
            customer_code: "111d2113",
            customer: "客户1",
            concrete_num: "3113441rrr12",
            product_name: "产品名称1",
            num: "11",
            price: "7.88",
            money: "102.00",
            car_num: "浙A666666",
            forwarder: "运输商1213",
            remark: "我是备注121"
          },
        ],

        footerData: [{
            delivery_date: "合计",
            consign_num: "",
            customer_code: "",
            customer: "",
            concrete_num: "",
            product_name: "",
            num: "115",
            price: "",
            money: "1020",
            car_num: "",
            forwarder: "",
            remark: "",
        }],
        cus_id: '',
        tenantId: '',
        token: ''
      };
    },

    created () {
      this.cus_id = localStorage.getItem('cid')
      this.tenantId = localStorage.getItem('tenantId')
      this.token = localStorage.getItem('token')
    },

    methods: {
      footerCellSpan({ row, column, rowIndex }) {
        if (column.field === "delivery_date") {
            return {
              rowspan: 1,
              colspan: 6,
            };
          }
          // does not need to be rendered
          else if (column.field === "consign_num") {
            return {
              rowspan: 0,
              colspan: 0,
            };
          } else if (column.field === "customer_code") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        } else if (column.field === "customer") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        } else if (column.field === "concrete_num") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        }  else if (column.field === "product_name") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        } 
      },

      search () {
        this.$router.push({ path: '/pssearch', query: { tid: this.tenantId, token: this.token, cid: this.cus_id } })
      }
    }
}
</script>

<style lang="less" scoped>
.report_box {
  width: 100%;
}

.report {
    width: 16rem;
    padding-bottom: 2rem;
}

/deep/ .table-header-cell-class {
    background: #29afa5 !important;
    color: #fff !important;
  }

   /deep/ .table-footer-cell-class1 {
    background: #91d5ff !important;
    color: #fff !important;
  }

 /deep/ .table-footer-cell-class2 {
    background: orange !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class3 {
    background: #ff5457 !important;
    color: #fff !important;
  }

  /deep/ .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td, .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
    padding: 0;
  }

  /deep/ .van-button--large {
  width: 100%;
  // width: 7.5rem;
  height: .8rem;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: #29afa5;
  // border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

// .search_btn {
//   position: fixed;
//   bottom: 3rem;
//   right: .5rem;
// }
</style>