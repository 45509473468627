<template>
  <div class="wechat-msg">
    <div v-wechat-title='$route.meta.title'></div>
    <div v-if="form != ''">
      <div class="qr-style" id="qrCode" ref="qrCodeDiv"></div>
      <van-divider dashed :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }" />
      <div class="info-box">
        <div class="info-title">订单信息</div>
        <div class="text-style">订单日期: {{form.setDate}}</div>
        <div class="text-style">订单编号: {{form.code}}</div>
        <div class="text-style">订单客商: {{form.clientName}}</div>
        <div class="text-style">订单品种: {{form.productName}}</div>
        <div class="text-style">订单车号: {{form.vehicle}}</div>
      </div>
    </div>

    <van-empty v-else description="暂无此订单信息" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "qrInfo",
  data() {
    return {
      saleDate: "",
      content: "",
      form: {}
    };
  },

  created() {
    this.orderId = this.$route.query.orderId;
    this.$nextTick(function () {
      this.getInfo(this.orderId)
    });
  },

  methods: {
    async getInfo(id) {
      const { data: res } = await this.$http.get(
        `/ymt/vehicleorder/getOrderInfoById/${id}`
      );
      console.log (res);
      if (res.code == 0) {
        this.form = res.data
        this.bindQRCode(id + '_' + res.data.tenantId);
      }

      console.log(this.form)
    },
    bindQRCode: function (id) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: id,
        width: 350,
        height: 350,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wechat-msg {
  font-size: 0.16rem;
  padding: 0.2rem 0.1rem;
  // text-align: center;
  margin: auto;
}

#qrCode {
  // width: 90%;
  margin: auto;
}

.info-box {
  margin-left: 0.2rem;
}

.info-title {
  color: #333;
  font-size: 0.2rem;
  font-weight: bold;
  line-height: 0.36rem;
}

.text-style {
  font-size: 0.18rem;
  // font-weight: bold;
  line-height: 0.32rem;
  // margin-top: .2rem;
}
</style>