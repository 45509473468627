import { render, staticRenderFns } from "./selfCarSp.vue?vue&type=template&id=fed644b4&scoped=true&"
import script from "./selfCarSp.vue?vue&type=script&lang=js&"
export * from "./selfCarSp.vue?vue&type=script&lang=js&"
import style0 from "./selfCarSp.vue?vue&type=style&index=0&id=fed644b4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed644b4",
  null
  
)

export default component.exports