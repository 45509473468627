<template>
  <div class="wechat-msg">
    <div v-if="detailsInfoList.length">
      <div class="info-box">
        <div class="info-title">详情信息</div>
        <div  v-for="item, key in detailsInfoList" :key="key">
            <div v-if="item.name=='remark'" class="text-style"> {{item.value}}</div>
        </div>
      </div>
    </div>

    <van-empty v-else description="暂无信息" />
  </div>
</template>

<script>
export default {
  name: "detailsInfo",
  data() {
    return {
      form: {},
      content:'',
      detailsInfoList:[]
    };
  },

  created() {
        console.log(this.$route.query)
        this.content = this.$route.query.content
        // this.content='[{"name": "first", "color": "#FF00FF", "value": "2023-05-05 09:04:06"}, {"name": "keyword1", "value": "京A88888"}, {"name": "keyword2", "value": "正在排队"}, {"name": "keyword3", "value": "0"}, {"name": "keyword4", "value": "农用氯化铵50kg"}, {"name": "keyword5", "value": "湖北双环测试"}, {"name": "remark", "color": "#FF00FF", "value": "尊敬的客户您好，您的车辆: 京A88888 所在排队车道 ：场外排队,尊敬的司机,你已被叫号成功,请进厂,请及时在规定时间内完成过磅,超时不过磅会被自动取消叫号"}]'
        this.detailsInfoList=JSON.parse(this.content);
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.wechat-msg {
  font-size: 0.16rem;
  padding: 0.2rem 0.2rem;
  // text-align: center;
  margin: auto;
  height: 100%;
  background-color: #F5F7FA;
}

#qrCode {
  // width: 90%;
  margin: auto;
}

.info-box {
  background-color: #fff;

  // margin-left: 0.2rem;
}

.info-title {
  padding: 0.1rem 0.24rem;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.36rem;
  border-bottom: 1px solid #F5F7FA;
}

.text-style {
  padding: 0.2rem 0.24rem;
  font-size: 0.14rem;
  line-height: 0.24rem;
  color: #666;
  // border: 1px solid;
    border-radius: 5px;
    // width: 300px;
    // height: 200px;
}


</style>