import { render, staticRenderFns } from "./PlaceOrder.vue?vue&type=template&id=69615801&scoped=true&"
import script from "./PlaceOrder.vue?vue&type=script&lang=js&"
export * from "./PlaceOrder.vue?vue&type=script&lang=js&"
import style0 from "./PlaceOrder.vue?vue&type=style&index=0&id=69615801&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69615801",
  null
  
)

export default component.exports