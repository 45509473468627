<template>
    <div class="car">
        <div v-wechat-title='$route.meta.title'></div>
        <van-search v-model="search_key" show-action placeholder="请输入搜索条件" @search="onSearch" shape="round">
            <template #action>
                <div @click="onSearch">搜 索</div>
            </template>
        </van-search>
        <div class="check-box">
            <van-checkbox-group v-model="result" direction="horizontal" @change="changeCar">
                <div class="check-tag" v-for="item in carList" :key="item.id">
                    <van-checkbox :name="item.id">{{ item.vehicle }}</van-checkbox>
                </div>
            </van-checkbox-group>
        </div>

        <div style="text-align:center; margin: .15rem 0 .1rem 0">
            <div style="margin-bottom:.03rem;color:#409EFF">点击添加到下方</div>
            <van-icon name="arrow-down" size="20" color="#409EFF" />
        </div>

        <div class="car-box">
            <div class="car-head">已选择车辆</div>
            <div class="car-list">
                <div class="car-tag" v-for="item in chooseCarList" :key="item.id">{{ item.vehicle }}</div>
            </div>
        </div>

        <van-button size="large" @click="submit">提交车辆</van-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search_key: '',
            result: [],
            cus_id: '',
            tenantId: '',
            carList: [],
            chooseCarList: []
        }
    },

    created() {
        this.tenantId = localStorage.getItem('tenantId')
        this.cus_id = localStorage.getItem('cid')
        this.chooseCarList = JSON.parse(sessionStorage.getItem('carList')) || []
        this.getCarList()
    },

    methods: {
        async getCarList() {
            const { data: res } = await this.$http.get(`/ymt/driver/page/${this.cus_id}`, {
                params: {
                    current: 1,
                    size: 1000
                }
            })
            console.log(res)
            if (res.code == 0) {
                this.carList = res.data.records
            }
        },

        changeCar(e) {
            let newCarList=this.carList.filter(item => e.indexOf(item.id) > -1);
            newCarList.forEach(item=>{
                let newChooseCarList=this.chooseCarList.filter(data => data.id==item.id)
                if(newChooseCarList.length==0){
                    this.chooseCarList.push(item);
                }
            })
            console.log('选定的数据-----',e)
            console.log('中间数据',this.chooseCarList)
            if(e.length!=this.chooseCarList.length){
                this.chooseCarList=this.chooseCarList.filter(item => e.indexOf(item.id) > -1);
            }    
            console.log('选中的数据',this.chooseCarList)
        },

        // onSearch () {},

        // 车牌号搜索
        async onSearch() {
            console.log('搜索')
            if (this.search_key !== '') {
                const { data: res } = await this.$http.get(`/ymt/driver/getDriverByKey/${this.search_key}`)
                console.log(res)
                if (res.code == 0) {
                    this.carList = res.data
                }
            } else {
                this.getCarList()
            }
        },

        submit() {
            if (this.chooseCarList.length <= 0) {
                this.$toast({
                    message: '请选择车号',
                    duration: 1000
                })
            } else {
                sessionStorage.setItem('carList', JSON.stringify(this.chooseCarList))
                this.$router.go(-1)
            }
        }
    }
}
</script>

<style lang="less" scoped>
// .car {
// overflow: hidden !important;
// }
/deep/ .van-search__action {
    color: #409EFF;
}

/deep/ .check-box {
    background-color: #fff;
    height: 2.4rem;
    margin: .1rem;
    padding: .2rem .1rem .1rem 0;
    overflow: scroll;

    .check-tag {
        width: 47%;
        height: .4rem;
        line-height: .4rem;
        background-color: #f2f2f2;
        margin-left: .1rem;
        padding-left: .3rem;
        margin-bottom: .1rem;
    }

    .van-checkbox {
        height: .4rem;
    }
}

.car-box {
    background-color: #fff;
    height: 2rem;
    margin: .1rem;

    .car-head {
        height: .4rem;
        line-height: .4rem;
        text-align: center;
        background-color: #409EFF;
        color: #fff;
    }

    .car-list {
        width: 100%;
        height: 1.6rem;
        overflow: scroll;
    }

    .car-tag {
        width: 30%;
        height: .4rem;
        line-height: .4rem;
        display: inline-block;
        text-align: center;
        background-color: #f2f2f2;
        margin: .1rem 0 0 .09rem;
    }
}

/deep/ .van-button--large {
    width: 95%;
    margin-top: .2rem;
    margin-bottom: .2rem;
    margin-right: .1rem;
    margin-left: .1rem;
    background: linear-gradient(to right, #4bb0ff, #6149f6);
    border-radius: .2rem;
    color: #fff;
}
</style>