<template>
  <div class="OrdererInfo">
    <div v-wechat-title='$route.meta.title'></div>
    <div class="orderer_info">订单信息</div>
    <van-field readonly v-model="ord_ent" label="订货企业：" placeholder="请选择订货企业" />
    <van-field readonly clickable label="订货日期：" :value="date_order" placeholder="" @click="showDate = true"/>
    <van-field readonly v-model="client" label="订货客户：" placeholder="请选择订货客户" @click="showCustomer = true" />
    <van-field readonly clickable label="代 理 商 ：" :value="agent" placeholder="请选择代理商" @click="showAgent = true"/>
    <van-field readonly clickable label="销售片区：" :value="sales_area" placeholder="请选择销售片区" @click="showSalesArea = true"/>
    <!-- <van-field readonly clickable label="工程项目：" :value="eng_pro" placeholder="请选择工程项目" @click="showEngPro = true"/> -->
    <!-- <van-field v-model="mobile" label="订货人电话：" placeholder="请输入订货人电话" /> -->
    <van-field readonly clickable label="运输类型：" :value="trans_mode" placeholder="请选择运输类型：" @click="showTrans = true"/>
    <van-field readonly clickable label="收 货 点 ：" :value="receive_point" placeholder="请选择收货点" @click="showReceive = true"/>
    <van-field v-model="address" type="text" label="收货地址：" placeholder="请输入收货地址" />
    <van-field readonly clickable label="运 输 商 ：" :value="shippers" placeholder="请选择运输商" @click="showShippers = true"/>
    <!-- <van-field name="radio" label="配送方式 ：">
    <template #input>
      <van-radio-group v-model="dis_mode" direction="horizontal">
        <van-radio name="1" checked-color="#29afa5">自提</van-radio>
        <van-radio name="2" checked-color="#29afa5">配送</van-radio>
      </van-radio-group>
    </template>
    </van-field> -->
    <!-- <van-field readonly v-model="address" label="收 货 地 址 ：" placeholder="请选择收货地址" /> -->
    <van-field v-model="remark" label="订货备注 ：" placeholder="请输入订货备注" />
    <van-button size="large" @click="nextStep">下一步</van-button>
    <!-- 选择订货客户 -->
    <van-popup v-model="showCustomer" position="bottom" >
      <div class="btn_box">
        <div @click="cancelCus">取消</div>
        <div style="color:#29afa5" @click="confirmCus">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_cus" placeholder="输入客商名称进行模糊搜索" :clearable=false />
        <div class="search_btn" @click="searchCusOrd">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in cusOrdList" :key="item.id" @click="chooseCusOrd(item)" :class="{ active: item.id === chooseCus.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择销售片区 -->
    <van-popup v-model="showSalesArea" position="bottom" >
      <div class="btn_box">
        <div @click="cancelSalesArea">取消</div>
        <div style="color:#29afa5" @click="confirmSalesArea">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_salesarea" placeholder="输入片区名称进行模糊搜索" :clearable=false />
        <div class="search_btn" @click="searchSalesArea">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name"  v-for="item in SalesArealList" :key="item.id" @click="chooseSalesArea(item)" :class="{ active: item.id === chooseSales.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择工程项目 -->
    <van-popup v-model="showEngPro" position="bottom" >
      <div class="btn_box">
        <div @click="cancelEngPro">取消</div>
        <div style="color:#29afa5" @click="confirmEngPro">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_engpro" placeholder="输入项目名称进行模糊搜索" :clearable=false />
        <div class="search_btn" @click="searchEngPro">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.productName"  v-for="item in engProList" :key="item.id" @click="chooseEngPro(item)" :class="{ active: item.id === chooseEng.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择订货时间 -->
    <van-popup v-model="showDate" position="bottom" >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :formatter="formatter"
        @confirm="confirmDate"
        @cancel="cancelDate"
        confirm-button-text="确定"
      />
    </van-popup>
    <!-- 选择运输商 -->
    <van-popup v-model="showShippers" position="bottom" >
      <div class="btn_box">
        <div @click="cancelShip">取消</div>
        <div style="color:#29afa5" @click="confirmShip">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_shippers" :clearable=false placeholder="输入运输商名称进行模糊搜索" />
        <div class="search_btn" @click="searchShipper">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in shippersList" :key="item.id" @click="chooseShipp(item)" :class="{ active: item.id === choose_ship.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择运输方式 -->
    <van-popup v-model="showTrans" position="bottom" >
      <van-picker
        show-toolbar
        :columns="columns_trans"
        @cancel="onTransCancel"
        @confirm="onTransConfirm"
        :default-index='0'
        confirm-button-text="确定"
        value-key="label"
      />
    </van-popup>
    <!-- 选择代理商 -->
    <van-popup v-model="showAgent" position="bottom" >
      <div class="btn_box">
        <div @click="cancelAgent">取消</div>
        <div style="color:#29afa5" @click="confirmAgent">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_agent" :clearable=false placeholder="输入客商名称进行模糊搜索" />
        <div class="search_btn" @click="searchAgent">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in AgentList" :key="item.id" @click="chooseAgent(item)" :class="{ active: item.id === choose_agent.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择收货点 -->
    <van-popup v-model="showReceive" position="bottom" >
      <van-picker
        show-toolbar
        :columns="receiveList"
        @cancel="cancelReceive"
        @confirm="confirmReceive"
        :default-index='0'
        confirm-button-text="确定"
        value-key="name"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'OrderInfo',
  data () {
    return {
      // 订货企业
      ord_ent: '',
      // 客户
      client: '',
      // 客户id
      clientId: '',
      // 运输商
      shippers: '',
      shippers_id: '',
      // 销售片区
      sales_area: '',
      // 工程项目
      eng_pro: '',
      // 电话
      mobile: '',
      // 订货时间
      date_order: '',
      // 需求时间选择框的显隐
      showDate: false,
      // 选择运输方式框的显隐
      showTrans: false,
      // 选择订货客户框的显隐
      showCustomer: false,
      // 选择运输商框的显隐
      showShippers: false,
      // 选择销售片区的显隐
      showSalesArea: false,
      // 选择工程项目的显隐
      showEngPro: false,
      // 当前时间
      currentDate: new Date(),
      // 运输方式
      trans_mode: '',
      trans_id: '',
      // 收货地址
      address: '',
      // 备注
      remark: '',
      //  选择订货客户搜索值
      search_cus: '',
      // 订货客户列表
      cusOrdList: [],
      cus_id: '',
      tenantId: '',
      token: '',
      // 选择的订货客户
      chooseCus: '',
      // 选择销售片区搜索值
      search_salesarea: '',
      // 选择运输商搜索值
      search_shippers: '',
      // 选择工程项目搜索值
      search_engpro: '',
      // 销售片区列表
      SalesArealList: [],
      // 工程项目列表
      engProList: [],
      // 选择的销售片区
      chooseSales: '',
      // 选择的工程项目
      chooseEng: '',
      // 运输商列表
      shippersList: [],
      // 选择的运输商
      choose_ship: '',
      // 运输方式列表
      columns_trans: [],
      engproId: '',
      salesAreaId:'',
      // 代理商弹框
      showAgent: false,
      agent: '',
      agentId: '',
      AgentList: [],
      search_agent: '',
      choose_agent: {},
      receive_point: '',
      showReceive: false,
      // 收货点
      receiveList: [],
      // 收货点id
      receiveId: '',
      customerOrderIdCopy: ''
    }
  },
  activated () {
    this.customerOrderIdCopy = localStorage.getItem('customerOrderIdCopy')
    this.date_order = this.util.getDate(new Date().getTime())
    this.cus_id = localStorage.getItem('cid')
    this.tenantId = localStorage.getItem('tenantId')
    this.token = localStorage.getItem('token')
    // 获取用户信息
    this.getCorp()
    // 获取订货客户
    this.getCusOrdList()
    // 获取运输商
    const types = '105'
    this.getShippersList(types)
    // 获取运输方式
    this.getTransportList()
  },
  created () {
    // this.date_order = this.util.getDate(new Date().getTime())
    // this.cus_id = localStorage.getItem('cid')
    // // 获取用户信息
    // this.getCorp()
    // // 获取订货客户
    // this.getCusOrdList()
    // // 获取运输商
    // const types = '105'
    // this.getShippersList(types)
    // // 获取运输方式
    // this.getTransportList()
  },
  methods: {
    // 获取用户信息
    async getCorp () {
      const { data: res } = await this.$http.get(`/admin/tenant/getListByCurUser/${this.cus_id}`)
      console.log(res)
      res.data.forEach(x => {
        if (x.id == this.tenantId) {
          this.ord_ent = x.name
        }
      })
    },
    // 获取订货客户列表
    async getCusOrdList (key) {
      const url = key && key !== '' ? '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + '102' + '/' + key :
      '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + '102'
      const { data: res } = await this.$http.get(`${url}`)
      console.log(res)
      // if (res.code === 0) {
      //   this.cusOrdList = res.data
      //   this.client = this.cusOrdList.length <= 0 ? '' : this.cusOrdList[0].name

      //   this.cusOrdList.forEach(item => {
      //     if (item.name === this.client) {
      //       this.chooseCus = item
      //       console.log(this.chooseCus)
      //     }
      //   })

      //   if (this.cusOrdList !== null && this.cusOrdList.length > 0) {
      //     this.clientId = this.cusOrdList[0].id
      //     // 获取销售片区
      //     this.getSalesArea(this.clientId, '')
      //     this.getAgentsList(this.clientId, '')
      //   }
      // }

      if (res.code === 0) {
        this.cusOrdList = res.data || []
        const flag = this.cusOrdList.some(x => {return x.id == this.customerOrderIdCopy})
        console.log(flag)
        if (flag) {
          // this.customer_order = this.cusOrdList.length <= 0 ? '' : this.cusOrdList[0].name
          this.cusOrdList.forEach(item => {
            if (item.id == this.customerOrderIdCopy) {
              this.chooseCus = item
              this.client = item.name
              this.clientId = item.id
            }
          })

          if (this.cusOrdList !== null && this.cusOrdList.length > 0) {
            console.log(this.clientId)
            this.getSalesArea(this.clientId, '')
            this.getAgentsList(this.clientId, '')
          }
        } else {
          this.client = this.cusOrdList.length <= 0 ? '' : this.cusOrdList[0].name

          this.cusOrdList.forEach(item => {
            if (item.name === this.client) {
              this.chooseCus = item
              console.log(this.chooseCus)
            }
          })

          if (this.cusOrdList !== null && this.cusOrdList.length > 0) {
            this.clientId = this.cusOrdList[0].id
            console.log(this.clientId)
            // 获取销售片区
            this.getSalesArea(this.clientId, '')
            this.getAgentsList(this.clientId, '')
            // this.getReceivingPoint(this.clientId, '')
          }
          }
        }
    },

    // 获取代理商
    async getAgentsList(cusid, key) {
      const { data: res } = await this.$http.post('/ymt/companymerchant/getMerchantByClient', {
        companyId: cusid,
        companyName: key,
        curUserId: this.cus_id
      })
      console.log(res)
      if (res.code == 0) {
        this.AgentList = res.data == null ? [] : res.data
        // if (this.AgentList.length <= 0) {
        //   this.agent = ''
        //   this.agentId = null
        // } else {
        //   this.agent = this.AgentList[0].name
        //   this.agentId = this.AgentList[0].id
        // }
        this.agent = this.AgentList.length <= 0 ? '' : this.AgentList[0].name
        this.agentId = this.AgentList.length <= 0 ? null : this.AgentList[0].id
        this.agentId = this.agentId === undefined ? null : this.agentId

        this.AgentList.forEach(item => {
          if (item.name === this.agent) {
            this.choose_agent = item
          }
        })
      }
    },

    // 获取销售片区
    async getSalesArea (cid , key) {
      console.log(cid, 'cid')
      const url = key && key !== '' ? '/ymt/block/queryblock/' + cid + '/' + key :
      '/ymt/block/queryblock/' + cid
      const { data: res } = await this.$http.get(`${url}`)
      console.log(res)
      if (res.code === 0) {
        this.SalesArealList = res.data == null ? [] : res.data
        if (this.SalesArealList.length <= 0) {
          this.sales_area = ''
          this.salesAreaId = null
          this.engProList = []
          this.engproId = null
          this.eng_pro = ''
        } else {
          this.salesAreaId = this.SalesArealList[0].id
          this.sales_area = this.SalesArealList[0].name
          this.getEngPro(this.salesAreaId, '')
        }
        // this.sales_area = this.SalesArealList.length <= 0 ? '' : this.SalesArealList[0].name
        this.SalesArealList.forEach(item => {
          if (item.name === this.sales_area) {
            this.chooseSales = item
          }
        })
      }
    },

    async getEngPro (sid, key) {
      const url = key && key !== '' ? '/ymt/companyproductname/queryproduct/' + sid + '/' + key :
      '/ymt/companyproductname/queryproduct/' + sid
      const { data: res } = await this.$http.get(`${url}`)
      console.log(res)
      if (res.code === 0) {
        this.engProList = res.data
        this.engProList.unshift({productName: '暂不选择'})
        this.eng_pro = this.engProList[0].productName
        this.engproId = this.engProList[0].id === undefined ? null :  this.engProList[0].id
        this.engProList.forEach(item => {
          if (item.productName === this.eng_pro) {
            this.chooseEng = item
          }
        })
      }
    },
    // 搜索销售片区
    searchSalesArea () {
      this.getSalesArea(this.clientId, this.search_salesarea)
    },
    // 确认选择销售片区
    confirmSalesArea () {
      this.sales_area = this.chooseSales.name
      this.salesAreaId = this.chooseSales.id
      this.showSalesArea = false
    },
    // 取消选择销售片区
    cancelSalesArea () {
      this.showSalesArea = false
    },

    chooseSalesArea (val) {
      this.chooseSales = val
    },

    searchEngPro () {
      if (this.salesAreaId == null || this.salesAreaId === '') {
        this.engProList = []
      } else {
        this.getEngPro(this.salesAreaId, this.search_engpro)
      }
    },

    chooseEngPro (val) {
      this.chooseEng = val
    },

    confirmEngPro () {
      console.log(this.chooseEng)
      this.eng_pro = this.chooseEng.productName
      this.engproId = this.chooseEng.id === undefined ? null : this.chooseEng.id
      this.showEngPro = false
    },

    cancelEngPro () {
      this.showEngPro = false
    },

    // 获取运输商列表
    async getShippersList (type, key) {
      const url = key && key !== '' ? '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + type + '/' + key :
      '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + type
      const { data: res } = await this.$http.get(`${url}`)
      console.log(res)
      if (res.code === 0) {
        this.shippersList = res.data
        this.shippersList.unshift({name: '暂不选择'})
        console.log(this.shippersList)
        this.shippers = this.shippersList.length <= 0 ? '' : this.shippersList[0].name
        this.shippers_id = this.shippersList.length <= 0 ? null : this.shippersList[0].id
        this.shippers_id = this.shippers_id === undefined ? null : this.shippers_id
        this.shippersList.forEach(item => {
          if (item.name === this.shippers) {
            this.choose_ship = item
            console.log(this.choose_ship)
          }
        })
        // console.log(this.shippers_id)
      }
    },

    // 获取运输方式列表
    async getTransportList () {
      const { data: res } = await this.$http.get('/admin/dict/type/transport_type')
      console.log(res)
      if (res.code === 0) {
        this.columns_trans = res.data
        this.trans_mode = this.columns_trans.length <= 0 ? null :  this.columns_trans[0].label
        this.trans_id = this.columns_trans.length <= 0 ? null :  this.columns_trans[0].value
        this.trans_id = this.trans_id === undefined ? null : this.trans_id
        this.getReceivingPoint(this.clientId, this.trans_id)
      }
    },

    // 获取收货点
    async getReceivingPoint (cid, tid) {
      const { data: res } = await this.$http.post('/ymt/company/getReceiveByCompany', {
        transportType: tid,
        companyId: cid
      })
      console.log(res)
      if (res.code == 0) {
        this.receiveList = res.data || []
        this.receive_point = this.receiveList.length <= 0 ? null :  this.receiveList[0].name
        this.receiveId = this.receiveList.length <= 0 ? null :  this.receiveList[0].id
        this.receiveId = this.receiveId === undefined ? null : this.receiveId
      }
    },

    // 选择订货客户
    confirmCus () {
      this.client = this.chooseCus.name
      this.clientId = this.chooseCus.id
      this.getSalesArea(this.clientId, '')
      this.getAgentsList(this.clientId, '')
      this.getReceivingPoint(this.clientId, this.trans_id)
      this.showCustomer = false
    },
    cancelCus () {
      this.showCustomer = false
    },
    searchCusOrd () {
      this.getCusOrdList(this.search_cus)
    },
    chooseCusOrd (val) {
      console.log(val)
      this.chooseCus = val
    },

    // 选择运输商
    confirmShip () {
      this.shippers = this.choose_ship.name
      this.shippers_id = this.choose_ship.id === undefined ? null : this.choose_ship.id
      this.showShippers = false
    },
    cancelShip () {
      this.showShippers = false
    },
    searchShipper () {
      const types = '105'
      this.getShippersList(types, this.search_shippers)
    },
    chooseShipp (val) {
      this.choose_ship = val
    },

    // 选择运输方式
    onTransConfirm (value) {
      this.trans_mode = value.label
      this.trans_id = value.value === undefined ? null : value.value 
      this.showTrans = false
      this.getReceivingPoint(this.clientId, this.trans_id)
    },
    onTransCancel () {
      this.showTrans = false
    },

    // 选择订货日期
    confirmDate (value) {
      console.log(value)
      const selectTime = `${this.value1}年${this.value2}月${this.value3}日`
      console.log('用户选择的日期', new Date(this.currentDate).getTime(), selectTime)
      this.showDate = false
      const time = new Date(this.currentDate).toLocaleDateString().split('/')
      if (Number(time[1]) < 10) { time[1] = '0' + time[1] }
      if (Number(time[2]) < 10) { time[2] = '0' + time[2] }
      this.date_order = time.join('-')
    },
    cancelDate () {
      this.showDate = false
    },
    // 下一步
    nextStep () {
      let warn = ''
      if (this.clientId === '' || this.clientId == null) {
        warn = '请选择订货客户'
      } else if (this.agentId === '' || this.agentId == null) {
        warn = '请选择代理商'
      } else if (this.date_order === '' || this.date_order == null) {
        warn = '请选择订货日期'
      } else {
        this.$router.push({ path: '/bluk_order', query: { tid: this.tenantId, token: this.token, cid: this.cus_id } })
        const orderInfo = {
            clientId: this.clientId,
            merchantId: this.agentId,
            setDate:this.date_order,
            transporter: this.shippers_id,
            transportType: this.trans_id,
            remark: this.remark,
            blockId: this.salesAreaId,
            productNameId: this.engproId,
            receiveId: this.receiveId,
            receiveName: this.receive_point,
            receiveAddress: this.address
          }
        localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
      }
      
      if (warn !== '') {
      this.$dialog({
        title: '提示',
        message: warn,
        confirmButtonColor: '#727AA1',
        showCancel: false
      }).then(() => {
      // on close
      })
    }
    },
    // 订货日期格式
    formatter (type, val) {
      if (type === 'year') {
        this.value1 = val
        return val + '年'
      }
      if (type === 'month') {
        this.value2 = val
        return val + '月'
      }
      if (type === 'day') {
        this.value3 = val
        return val + '日'
      }
      return val
    },

    confirmAgent () {
      this.agent = this.choose_agent.name
      this.agentId = this.choose_agent.id === undefined ? null : this.choose_agent.id
      this.showAgent = false
    },

    cancelAgent () {
      this.showAgent = false
    },

    searchAgent () {
      this.getAgentsList(this.clientId, this.search_agent)
    },

    chooseAgent (val) {
      this.choose_agent = val
    },

    cancelReceive () {
      this.showReceive = false
    },

    confirmReceive (value) {
      console.log(value)
      this.receive_point = value.name
      this.receiveId = value.id === undefined ? null : value.id 
      this.showReceive = false
    }
  }
}
</script>

<style lang="less" scoped>
.OrdererInfo {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: .1rem;
//   background-color: #f7f7f7;
}

.orderer_info {
  padding: .1rem .16rem;
  border-bottom: 1px solid #e1e1e1;
  color:#999;
}

.van-cell {
  height: .5rem;
  line-height: .5rem;
  padding-left: .2rem;
  border-bottom: 1px solid #e1e1e1;
  font-size: .14rem;
  padding: 0 .16rem;
}

/deep/ .van-field__label {
  width: .88rem;
  margin-right: 0;
//   text-align-last:justify;
//   text-align:justify;
//   text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
}

/deep/ .van-picker__cancel {
  font-size: .16rem;
}

/deep/ .van-picker__confirm {
  font-size: .16rem;
  color: #29afa5;
}

/deep/ .van-field__control--custom {
  min-height: .5rem;
}

/deep/ .van-radio--horizontal {
    margin-right: .2rem;
}

/deep/ .van-button--large {
  width: 95%;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: linear-gradient(to right, #4bb0ff, #6149f6);
  border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.btn_box {
  display: flex;
  justify-content: space-between;
  padding: .1rem .16rem;
  font-size: .16rem;
  color: #969799;
  border-bottom: 1px solid #D7D6DC;
}

/deep/ .van-search {
  width: 3.22rem;
}

/deep/ .van-search .van-cell {
  height: .4rem;
  line-height: .4rem;
  border-bottom:none;
  padding-left: 0;
}

/deep/ .van-search__content {
  background-color: #fff;
  border: .01rem solid #E6E6EA;
  border-radius: .2rem;
}

.search_btn {
  line-height: .62rem;
  font-size: .16rem;
  color: #29afa5;
}

/deep/ .van-search .van-field__control {
  line-height: .4rem;
  font-size: .14rem;
}

/deep/ .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
  font-size: .16rem;
  color: #969799;
}

/deep/ .van-cell-group {
  height: 2rem;
  overflow: scroll;
  font-size: .16rem;
  text-align: center;
  border: none;
  border-top: 1px solid #D7D6DC;
  padding: .1rem 0;
  .van-cell {
    border-bottom: none;
    padding: 0;
    height: .45rem;
    line-height: .45rem;
    color: #999;
    font-weight: 700;
  }
}

</style>