<template>
  <div class="OrdererInfo">
    <div v-wechat-title='$route.meta.title'></div>
    <div style="margin-bottom:1.6rem">
      <div v-for="(item,i) in infoList" :key="i">
      <div class="order_info_head">
        <span style="color:#999;">订单信息</span>
        <span style="color:#6495ED;" v-show="infoList.length > 1 " @click="delOrder(i)">删除</span>
      </div>
      <van-field readonly v-model="item.var_order" label="订货品种：" placeholder="请选择品种" :required=true @click="item.showVarieties = true" />
      <van-field name="stepper" label="订货数量：" :required=true>
        <template #input>
          <van-stepper v-model="item.num" input-width=60 min=0 />
        </template>
      </van-field>
      <!-- 车牌号码 -->
    <van-field
      v-model="item.plate_number"
      center
      label="车牌号码："
      readonly
      placeholder="请输入车牌号码"
      @focus="showKeyWords(i)"
      v-show="orderInfo.transportType == '101'"
      :required=true
    >
      <template #button>
        <van-button round plain color="#eb2444" type="info" @click="()=>{item.showPlateNum = true;}">选择</van-button>
      </template>
    </van-field>
    <van-field v-model="item.driver" :onkeyup="item.driver = item.driver.replace(/\s+/g,'')" label="司机姓名：" placeholder="请输入司机姓名" :required=true v-show="orderInfo.transportType == '101'" />
    <van-field v-model="item.id_card" label="证 件 号 ：" :onkeyup="item.id_card = item.id_card.replace(/\s+/g,'')" placeholder="请输入证件号" :required=true v-show="orderInfo.transportType == '101'" />
    <van-field v-model="item.mobile" :onkeyup="item.mobile = item.mobile.replace(/\s+/g,'')" label="联系电话：" placeholder="请输入联系电话" :required=true v-show="orderInfo.transportType == '101'" />
    <van-field v-model="item.car_num" label="订车数量：" :required=true type="digit" placeholder="请输入车数" />
    <div class="contain_cell" @click="add" v-show="item.isShowAddBtn">
      <van-button icon="plus" type="info" />
      <span>继续添加</span>
    </div>
    <!-- 选择订货品种 -->
    <van-popup v-model="item.showVarieties" position="bottom" >
      <div class="btn_box">
        <div @click="cancelMat(i)">取消</div>
        <div style="color:#29afa5" @click="confirmMat(i)">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="item.search_var" :clearable=false placeholder="输入产品/物料名称进行模糊搜索" />
        <div class="search_btn" @click="searchMaterial(i)">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item1.name"  v-for="item1 in item.materialList" :key="item1.id" @click="chooseMater(item1, i)" :class="{ active: item1.id === item.chooseMat.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择车牌号码弹框-->
    <van-popup v-model="item.showPlateNum" position="bottom" >
      <div style="display:flex;border-bottom:1px solid #D7D6DC;">
        <van-search v-model="item.search_licensNum" :clearable=false placeholder="输入客商名称/车牌号码进行模糊搜索" />
        <div class="search_btn" @click="searchPlateNum(i)">搜索</div>
      </div>
      <van-list style="height:220px;overflow:auto" v-model="loading" :finished="finished" :finished-text="finished_text" @load="onload" :immediate-check="false" :offset="100">
        <div class="plate_box" v-for="item in car_list" :key="item.id" @click="chooseAgInfo(item, i)">
          <span v-show="item.driver !== null">{{item.driver}}</span>
          <span>{{item.vehicle}}</span>
          <span>{{item.idcard}}</span>
        </div>
      </van-list>
    </van-popup>
    <!-- 车牌键盘 -->
    <keyword :isShow="item.keyState" @exit="exit(i)" @inputchange="getKey" :oinp="item.str" @ok="keywordconfirm"/>
    </div>
    </div>
    <van-button size="large" loading-text="加载中..." :loading="btnLoading" @click="BKbtn">提交订单</van-button>
  </div>
</template>

<script>
import keyword from '../../components/keyword'
import wx from 'weixin-js-sdk'
export default {
  components: {
    keyword
  },
  name: 'BlukOrder',
  data () {
    return {
      btnLoading: false,
      orderInfo: {},
      // 用户对应的订货品种列表
      cus_id: '',
      tenantId: '',
      car_list: [],
      car_list_copy: [],
      loading: false,
      finished: false,
      isTime: false,
      current_page: 1,
      finished_text: '',
      infoList: [
        {
          materialList: [],
          // 品种
          var_order:'',
          varord_id: '',
          // 数量
          num: 0,
          // 司机
          driver:'',
          id_card: '',
          mobile: '',
          // 车号
          plate_number: '',
          // 选择订货品种弹框
          showVarieties: false,
          // 选择订货品种搜索框的值
          search_var: '',
          // 选择车牌号码弹框
          showPlateNum: false,
          // 选择车牌号码搜索框的值
          search_licensNum: '',
          // 车数
          car_num: 1,
          // 继续添加按钮的显隐
          isShowAddBtn: true,
          // 车牌键盘的显隐
          keyState: false,
          // 车牌键盘输入的内容
          str: '',
          chooseMat: '',
          // materialList: []
        }
      ],
      KId: '',
    }
  },
  // created () {
  //   console.log(this.$route.params.orderInfo)
  // },
  activated () {
    this.cus_id = localStorage.getItem('cid')
    this.tenantId = localStorage.getItem('tenantId')
    console.log(JSON.parse(localStorage.getItem('orderInfo')))
    this.orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
    const type = '101'
    this.getMateriaList(type, '', 0)
    this.getCarData()
  },
  methods: {
    // 获取当前用户对应的订货品种
    async getMateriaList (type, key, i) {
      console.log(i)
      // const url = key && key !== '' ? '/ymt/company/getMaterialInfo' + '/' + key : '/ymt/company/getMaterialInfo/'
      const url = '/ymt/company/getMaterialInfo'
      const { data: res } = await this.$http.post(`${url}`, { 
          type: type,
          id: this.orderInfo.clientId,
          block: this.orderInfo.blockId,
          key: key
       })
      console.log(res)
      if (res.code === 0) {
        this.infoList[i].materialList = res.data
        this.infoList[i].var_order = this.infoList[i].materialList.length <= 0 ? '' : this.infoList[i].materialList[0].name
        this.infoList[i].varord_id = this.infoList[i].materialList.length <= 0 ? '' : this.infoList[i].materialList[0].cloudId
        this.infoList[i].materialList.forEach(item => {
          if (item.name === this.infoList[i].var_order) {
            this.infoList[i].chooseMat = item
            console.log(this.infoList[i].chooseMat)
          }
        })
      }
    },
    chooseMater (val, i) {
      console.log(val)
      console.log(i)
      console.log(this.infoList, 'this.infoList')
      this.infoList[i].chooseMat = val
    },

    onload() {
      this.getCarData()
    },

    // 获取车辆列表
    async getCarData () {
      console.log(this.isTime, 'on');
      if (this.isTime) {
        return
      }
      setTimeout(() => {
        this.isTime = false
        console.log(this.isTime, 'set');
      }, 500)
      // this.current_page = this.current_page + 1
      console.log(this.loading, 'loading');
      this.isTime = true

      console.log(this.current_page, 'this.current_page', this.isTime);
      const { data: res } = await this.$http.get(`/ymt/driver/page/${this.cus_id}`, {
        params: {
          current: this.current_page,
          size: 100
        }
      })
      console.log(res)
      if (res.code === 0) {
        // this.car_list = res.data.records
        res.data.records.forEach(item => this.car_list.push(item))
        res.data.records.forEach(item => this.car_list_copy.push(item))
        console.log(this.car_list)
        this.loading = false
        this.current_page = res.data.current + 1
        console.log(this.current_page)
        if (this.car_list.length >= res.data.total) {
          this.finished_text = '没有更多了'
          this.finished = true
          return
        }
        if (this.car_list.length === 0) {
          this.finished_text = '没有更多了'
          this.finished = true
          return
        }
        console.log('--------------111111111111-------------')
      }
    },

    // 车牌号搜索
    async searchPlateNum (i) {
      if (this.infoList[i].search_licensNum !== '') {
        const { data: res } = await this.$http.get(`/ymt/driver/getDriverByKey/${this.infoList[i].search_licensNum}`)
        console.log(res)
        if (res.code === 0) {
          this.car_list = res.data
          this.finished_text = this.car_list.length <= 0 ? '' : '没有更多了'
        }
      } else {
        this.car_list = this.car_list_copy
        this.finished_text = this.car_list.length <= 0 ? '' : '没有更多了'
      }
    },

     // 选择车牌信息
    chooseAgInfo (val, i) {
      console.log(val)
      console.log(i)
      this.infoList[i].plate_number = val.vehicle || ''
      this.infoList[i].driver = val.driver || ''
      this.infoList[i].id_card = val.idcard || ''
      this.infoList[i].mobile = val.phone || ''
      this.infoList[i].str = val.vehicle || ''
      this.infoList[i].showPlateNum = false
    },

    // 继续添加
    add() {
      this.$dialog({
        title: '提示',
        message: '是否复制填写内容',
        confirmButtonColor: '#727AA1',
        showCancelButton: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
      }).then(() => {
        console.log('确认')
        this.infoList.push({
              // 品种
              materialList: this.infoList[0].materialList,
              var_order: this.infoList[0].var_order,
              varord_id: this.infoList[0].varord_id,
              // 数量
              num: this.infoList[0].num,
              // 司机
              driver: this.infoList[0].driver,
              id_card: this.infoList[0].id_card,
              mobile: this.infoList[0].mobile,
              // 车号
              plate_number: this.infoList[0].plate_number,
              // 选择订货品种弹框
              showVarieties: false,
              // 选择订货品种搜索框的值
              search_var: this.infoList[0].search_var,
              // 选择车牌号码弹框
              showPlateNum: false,
              // 选择车牌号码搜索框的值
              search_licensNum: this.infoList[0].search_licensNum,
              // 车数
              car_num: this.infoList[0].car_num,
              // 继续添加按钮的显隐
              isShowAddBtn: true,
              // 车牌键盘的显隐
              keyState: false,
              // 车牌键盘输入的内容
              str: this.infoList[0].str,
              chooseMat: this.infoList[0].chooseMat
              // materialList: this.infoList[0].materialList
          })
      }).catch(() => {
        console.log('取消')
        this.infoList.push({
            materialList: this.infoList[0].materialList,
             // 品种
            var_order:'',
            varord_id: '',
            // 数量
            num: 0,
            // 司机
            driver:'',
            id_card: '',
            mobile: '',
            // 车号
            plate_number: '',
            // 选择订货品种弹框
            showVarieties: false,
            // 选择订货品种搜索框的值
            search_var: '',
            // 选择车牌号码弹框
            showPlateNum: false,
            // 选择车牌号码搜索框的值
            search_licensNum: '',
            // 车数
            car_num: 1,
            // 继续添加按钮的显隐
            isShowAddBtn: true,
            // 车牌键盘的显隐
            keyState: false,
            // 车牌键盘输入的内容
            str: '',
            chooseMat: ''
            // materialList: []
        })
      })
        if (this.infoList.length > 1) {
          this.infoList[0].isShowAddBtn = false
        }
    },
    // 确认选择订货品种
    confirmMat (i) {
      this.infoList[i].var_order = this.infoList[i].chooseMat.name
      this.infoList[i].varord_id = this.infoList[i].chooseMat.cloudId === undefined ? null : this.infoList[i].chooseMat.cloudId
      this.infoList[i].showVarieties = false
    },
    // 取消选择订货品种
    cancelMat (i) {
      this.infoList[i].showVarieties = false
    },
    // 搜索订货品种
    searchMaterial (i) {
      const type = '101'
      this.getMateriaList(type, this.infoList[i].search_var, i)
    },
    // 删除
    delOrder (i) {
      console.log(i)
      this.infoList = this.infoList.filter((item, index) => { return index !== i })
      // console.log(this.infoList)
    },
    // 批量下单按钮
    BKbtn () {
      this.btnLoading = true
      console.log(this.infoList)
      let warn = ''
      let flag1 = false
      let flag2 = false
      let flag3 = false
      let flag4 = false
      let flag5 = false
      let flag6 = false
      let flag7 = false
      if (this.orderInfo.transportType == '101') {
        flag1 = this.infoList.some(item => {
          return item.varord_id == '' || item.varord_id == null
        })
        flag2 = this.infoList.some(item => {
          return item.num == 0 || item.num == null
        })
        flag3 = this.infoList.some(item => {
          return item.plate_number == '' || item.plate_number == null
        })
        flag4 = this.infoList.some(item => {
          return item.driver == 0 || item.driver == null
        })
        flag5 = this.infoList.some(item => {
          return item.id_card == 0 || item.id_card == null
        })
        flag6 = this.infoList.some(item => {
          return item.mobile == 0 || item.mobile == null
        })
        flag7 = this.infoList.some(item => {
          return item.car_num == 0 || item.car_num == null
        })
      } else {
        flag1 = this.infoList.some(item => {
          console.log('----')
          return item.varord_id == '' || item.varord_id == null
        })
        flag2 = this.infoList.some(item => {
          return item.num == 0 || item.num == null
        })
        flag3 = this.infoList.some(item => {
          return false
        })
        flag4 = this.infoList.some(item => {
          return item.car_num == 0 || item.car_num == null
        })
      }
      if (flag1) {
        console.log(flag1)
        warn = '请选择订货品种'
      } else if (flag2) {
        warn = '请填写订货数量，并确保数量大于1'
      } else if (flag3) {
        warn = '请填写车牌号码'
      } else if (flag4) {
        warn = '请填写司机姓名'
      } else if (flag5) {
        warn = '请填写证件号'
      } else if (flag6) {
        warn = '请填写联系电话'
      } else if (flag7) {
        warn = '请填写订车数量，并确保数量大于1'
      } else {
        let orderInfo = []
        if (this.orderInfo.transportType == '101') {
          this.infoList.forEach(item => {
            orderInfo.push({
              productId: item.varord_id,
              orderNumber: item.num,
              vehicle: item.plate_number,
              driver: item.driver,
              idCard: item.id_card,
              phone: item.mobile,
              orderCarNumber: item.car_num
            })
          })
        } else {
          this.infoList.forEach(item => {
            orderInfo.push({
              productId: item.varord_id,
              orderNumber: item.num,
              orderCarNumber: item.car_num
            })
          })
        }
        this.$http.post(`/ymt/vehicleorder/saveVehicleOrderForBatchOrder/${this.cus_id}`,
        {
          corp: this.tenantId,
          setDate: this.orderInfo.setDate,
          clientId: this.orderInfo.clientId,
          merchantId: this.orderInfo.merchantId,
          blockId: this.orderInfo.blockId,
          productNameId: this.orderInfo.productNameId,
          transporter: this.orderInfo.transporter,
          transportType: this.orderInfo.transportType,
          remark: this.orderInfo.remark,
          receiveId: this.orderInfo.receiveId,
          receiveName: this.orderInfo.receiveName,
          receiveAddress: this.orderInfo.receiveAddress,
          orderInfo: orderInfo
      }).then(res => {
          console.log(res)
        if (res.status == 200 && res.data.code == 0) {
          localStorage.setItem('customerOrderIdCopy', this.orderInfo.clientId)
          this.$dialog({
            message: res.data.msg == null ? '下单成功' : res.data.msg,
            theme: 'round-button'
          }).then(() => {
            this.btnLoading = false
            console.log('下单成功')
            wx.miniProgram.navigateTo({
              url: '/pages/orderList/orderList'
            })
          })
        } else {
          let err_msg = res.data.msg ? res.data.msg : '服务器出了点小差'
          this.btnLoading = false
          this.$dialog({
            message: err_msg,
            theme: 'round-button'
          }).then(() => {
            this.btnLoading = false
            console.log('下单失败')
          })
        }
        }).catch(err => {
          console.log(err)
          this.btnLoading = false
            this.$dialog({
              title: '提示',
              message: err.data.msg,
              confirmButtonColor: '#727AA1',
              showCancel: false
            }).then(() => {
            })
        })
      
      }
      
      if (warn !== '') {
      this.$dialog({
        title: '提示',
        message: warn,
        confirmButtonColor: '#727AA1',
        showCancel: false
        // success: function (res) {
        //   if (res.confirm) {
        //     console.log('用户点击确定')
        //     this.btnLoading = false
        //   }
        // }
      }).then(() => {
      // on close
        // console.log(eeeeeeeeeee)
        this.btnLoading = false
      })
    }
    },
    // 车牌键盘事件
    showKeyWords (i) {
      console.log(i)
      this.KId = i
      console.log(this.KId)
      this.infoList.forEach((item, index) => {
        if (i === index) {
          item.keyState = true
        } else {
          item.keyState = false
        }
      })
    },
    exit (i) {
      this.infoList[i].keyState = false
    //   console.log(this.str, 'this.str')
    //   console.log(this.plate_number, 'this.plate_number')
    },

    // 车牌号键盘输入的内容
    getKey (val) {
      // console.log(i)
      console.log(this.KId)
      console.log(val)
      if (this.infoList[this.KId].str.length >= 8 && val !== 'delete') {
        return false
      }
      if (val === 'delete') {
        this.infoList[this.KId].str = this.infoList[this.KId].str.slice(0, this.infoList[this.KId].str.length - 1)
        this.infoList[this.KId].plate_number = this.infoList[this.KId].str
      } else {
        this.infoList[this.KId].str += val
        this.infoList[this.KId].plate_number = this.infoList[this.KId].str
      }
    },

    // 车牌号键盘点击确认
    keywordconfirm (e) {
      console.log(e)
      this.infoList[this.KId].plate_number = this.infoList[this.KId].str
      this.infoList[this.KId].keyState = false
    }
  }
}
</script>

<style lang="less" scoped>
.OrdererInfo {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: .1rem;
  margin-bottom: .4rem;
//   background-color: #f7f7f7;
}

.order_info_head {
  display: flex;
  justify-content: space-between;
  padding: .1rem .16rem;
  border-bottom: 1px solid #e1e1e1;
}

.van-cell {
  height: .5rem;
  line-height: .5rem;
  padding-left: .2rem;
  border-bottom: 1px solid #e1e1e1;
  font-size: .14rem;
  padding: 0 .16rem;
}

/deep/ .van-field__label {
  width: .88rem;
  margin-right: 0;
//   text-align-last:justify;
//   text-align:justify;
//   text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
}

/deep/ .van-picker__cancel {
  font-size: .16rem;
}

/deep/ .van-picker__confirm {
  font-size: .16rem;
  color: #29afa5;
}

/deep/ .van-field__control--custom {
  min-height: .5rem;
}

/deep/ .van-button--large {
  width: 95%;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: linear-gradient(to right, #4bb0ff, #6149f6);
  border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

/deep/ .van-stepper__minus {
  margin-right: .1rem;
  border-radius: .15rem;
}

/deep/ .van-stepper__plus {
  margin-left: .1rem;
  border-radius: .15rem;
}

.search_btn {
  line-height: .62rem;
  font-size: .16rem;
  color: #29afa5;
}

.plate_box {
  display: flex;
  justify-content: space-between;
  height: .45rem;
  line-height: .45rem;
  padding: 0 .2rem;
  border-bottom: 1px solid #D7D6DC;
}

/deep/ .van-search {
  width: 3.22rem;
}

/deep/ .van-search .van-cell {
  height: .4rem;
  line-height: .4rem;
  border-bottom:none;
  padding-left: 0;
}

/deep/ .van-search__content {
  background-color: #fff;
  border: .01rem solid #E6E6EA;
  border-radius: .2rem;
}

/deep/ .van-search .van-field__control {
  line-height: .4rem;
  font-size: .14rem;
}

/deep/ .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
  font-size: .16rem;
  color: #969799;
}

/deep/ .van-field__button .van-button {
  width: .65rem;
  height: .32rem;
  font-size: .14rem;
  vertical-align: middle;
}

/deep/ .van-field__button .van-button:focus {
  background-color: #fff;
}

.contain_cell {
  height: .5rem;
  line-height: .5rem;
  border-bottom: 1px solid #e1e1e1;
  padding-left: .18rem;
  font-size: .14rem;
  background-color: #fff;
  color: #1989fa;
  .van-button {
    width: .24rem;
    height: .24rem;
    vertical-align: middle;
    margin-right: .15rem;
    font-size: .12rem;
  }
}

.van-button--info {
  background-color: #fff;
  color: #1989fa;
}

.van-button--normal {
  padding: 0 .1rem;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  padding: .1rem .16rem;
  font-size: .16rem;
  color: #969799;
  border-bottom: 1px solid #D7D6DC;
}

/deep/ .van-cell-group {
  height: 2rem;
  overflow: scroll;
  font-size: .16rem;
  text-align: center;
  border: none;
  border-top: 1px solid #D7D6DC;
  padding: .1rem 0;
  .van-cell {
    border-bottom: none;
    padding: 0;
    height: .45rem;
    line-height: .45rem;
    color: #999;
    // font-weight: 700;
  }
}
</style>