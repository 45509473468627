<template>
  <div class="traceInfo">
    <div v-wechat-title='$route.meta.title'></div>
    <van-overlay :show="showOverlay">
      <div class="wrapper" @click.stop>
        <van-loading v-show="showLoading" type="spinner" size="48" class="loading-style" color="#1989fa" vertical>正在加载中...</van-loading>
      </div>
    </van-overlay>
    <van-tabs v-model="active1" type="card" color="#36af41" line-height="100px" style="margin-top:.1rem">
      <van-tab title="追溯码信息">
        <div class="info-content" style="height: 2.36rem">
          <div class="info-cell"><span style="color:#747d8c">产品名称：</span>{{traceInfo.productName || ''}}</div>
          <div class="info-cell"><span style="color:#747d8c">产品图片：</span>
            <div class="img-box">
              <img class="img-style" v-for="(item, index) in traceInfo.productPhotoList" :key="index" @click="lookImg(index)" :src="item.address" width="80" height="80" alt="">
            </div>
          </div>
          <div class="info-cell"><span style="color:#747d8c">生产单位：</span>{{traceInfo.companyName}}</div>
          <div class="info-cell"><span style="color:#747d8c">追溯码号：</span>{{traceInfo.code}}</div>
          <div class="info-cell"><span style="color:#747d8c">生产日期：</span>{{traceInfo.createDate}}</div>
          <div class="info-cell"><span style="color:#747d8c">质保期：</span>{{traceInfo.productWarranty}} 年</div>
        </div>
      </van-tab>
      <van-tab title="联系人信息">
        <div class="info-content" style="height: 2.36rem;overflow: scroll;">
          <div class="sb" v-for="(item, index) in traceInfo.personList" :key="index">
            <div class="info-cell">
              <van-icon name="bookmark" color="#ff4757" size=".16rem" style="vertical-align: middle;" /> <span style="vertical-align: middle;">{{item.job}}</span>
            </div>
            <div class="info-cell">
              <van-icon name="manager" color="#1abc9c" size=".16rem" style="vertical-align: middle;" /> <span style="vertical-align: middle;">{{item.name}}</span>
            </div>
            <div class="info-cell">
              <van-icon name="phone" color="#1989fa" size=".16rem" style="vertical-align: middle;" /> <span style="vertical-align: middle;">{{item.phone}}</span>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <van-tabs v-model="active2" type="card" swipeable color="#36af41" line-height="100px" style="margin-top:.1rem">
      <van-tab title="参数信息">
        <div class="info-content" style="height: 1.2rem;overflow: scroll;margin-bottom: .2rem">
          <div class="sb" v-for="(item, index) in traceInfo.parameterList" :key="index">
            <div class="info-cell"><span style="color:#747d8c">名称：</span>{{item.name}}</div>
            <div class="info-cell"><span style="color:#747d8c">类型：</span>{{item.type}}</div>
            <div class="info-cell"><span style="color:#747d8c">单位：</span>{{item.unit}}</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="质量信息">
        <div class="info-content" style="height: 1.2rem;overflow: scroll;margin-bottom: .2rem">
          <div class="sb">
            <div class="info-cell" style="display:flex"><span style="color:#747d8c">质检单：</span><img class="img-style" v-if="traceInfo.qualityUrl != undefined" @click="lookImg1(traceInfo.qualityUrl)" :src="traceInfo.qualityUrl" width="80" height="80" alt=""></div>
            <div class="info-cell" style="display:flex"><span style="color:#747d8c">合格证：</span><img class="img-style" v-if="traceInfo.certificateUrl != undefined" @click="lookImg1(traceInfo.certificateUrl)" :src="traceInfo.certificateUrl" width="80" height="80" alt=""></div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <div class="info-box" style="margin-top: -.1rem;border-bottom: none">
      <div style="display:flex">
        <span class="info-box-head-son">生产信息</span>
        <span class="topleft-triangle"></span>
      </div>
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(item, index) in traceInfo.debugList" :key="index">
          <h3 style="margin-top: 0">{{item.setDate}}</h3>
          <h3>{{item.stateName}}</h3>
          <!-- <p style="font-size: .14rem" ></p> -->
          <div v-html="item.content"></div>
        </van-step>
      </van-steps>
    </div>

    <div class="service" @click="toService">
      <van-icon name="service" color="#1e90ff" size=".32rem" style="vertical-align: middle;" />
      <div class="service-text">售后服务</div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      active1: 0,
      active2: 0,
      traceInfo: "",
      imgList: [],
      coordinate: "",
      coordinateName: "",
      signAddress: "",
      showOverlay: false,
      showLoading: false,
    };
  },

  mounted() {
    this.getAddress();
  },

  created() {
  },

  methods: {
    async getTraceInfo() {
      this.showOverlay = true;
      this.showLoading = true;
      let code = this.$route.query.code;
      const { data: res } = await this.$http.post(
        "/zs/retrospect/checkRetrospect",
        {
          code: code,
          coordinate: this.coordinate,
          coordinateName: this.coordinateName,
        }
      );

      console.log(res);
      this.showOverlay = false;
      this.showLoading = false;
      if (res.code == 0) {
        this.traceInfo = res.data;
        this.imgList = this.traceInfo.productPhotoList.map((item) => {
          return item.address;
        });
        this.traceInfo.createDate = this.traceInfo.createDate.split(" ")[0];
      }
    },

    lookImg(index) {
      ImagePreview({
        images: this.imgList,
        startPosition: index,
        onClose() {},
      });
    },

    lookImg1(img) {
      ImagePreview({
        images: [img],
        startPosition: 0,
        onClose() {},
      });
    },

    getAddress() {
      let that = this;
      var geolocation = new BMapGL.Geolocation();
      // 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function (res) {
        console.log(res, "获取定位");

        that.coordinateName = res.address.province + res.address.city;
        that.coordinate = res.longitude + "," + res.latitude;

        that.$nextTick(() => {
          that.getTraceInfo();
        });

        if (res.accuracy == null) {
          // that.load = false;
          console.log("定位失败,请开启定位！"); //用户拒绝地理位置授权
          return;
        }
      });
    },

    toService() {
      this.$router.push({ path: "/service", query: { id: this.traceInfo.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.traceInfo {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  margin: 0.1rem;

  .info-box {
    // width: 100%;
    margin: 0.1rem;
    padding-bottom: 0.1rem;
    border-bottom: 0.02rem solid #8ed194;
  }

  .info-box-head {
    width: 100%;
    text-align: center;
    line-height: 0.4rem;
    background-color: #36af41;
    color: #fff;
    font-weight: bold;
    font-size: 0.16rem;
  }

  .info-box-head-son {
    display: inline-block;
    width: 1rem;
    line-height: 0.4rem;
    text-align: center;
    background-color: #36af41;
    color: #fff;
    font-size: 0.16rem;
    font-weight: bold;
  }

  .topleft-triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-right: 0.2rem solid transparent;
    border-top: 0.4rem solid #36af41;
  }

  .info-content {
    padding: 0.1rem 0.2rem;
    font-size: 0.14rem;
  }

  .info-cell {
    line-height: 0.24rem;
  }
}

.sb {
  display: flex;
  justify-content: space-between;
}

/deep/ .van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0;
}

/deep/ .van-tab__text--ellipsis {
  font-size: 0.16rem;
  font-weight: bold;
}

/deep/ .van-tabs--card > .van-tabs__wrap,
/deep/ .van-tabs__nav--card {
  height: 0.4rem;
}

.img-box {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  // padding-bottom: 0.1rem;

  .img-style {
    margin: 0 0.05rem;
  }
}

.service {
  position: fixed;
  bottom: 0.8rem;
  right: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .service-text {
    color: #1e90ff;
    font-size: 0.12rem;
  }
}

.loading-style {
  width: 100%;
  margin: 2.5rem 0 0 0;
}
</style>