<template>
    <div class="report_box">
      <div class="report">
        <div v-wechat-title='weChatTitle'></div>
        <ve-table
          fixed-header
          border-y
          :columns="columns"
          :table-data="tableData"
          row-key-field-name="rowKey"
          :cell-style-option="cellStyleOption"
          :row-style-option="rowStyleOption"
          :cell-span-option="cellSpanOption"
        />
    </div><!-- :footer-data="footerData" -->
    <van-button size="large" @click="search">
      <ve-icon name="filter" :size='60' />
    </van-button>
    </div>
</template>

<script>
export default {
    name: 'Report',
    data() {
      return {
        cellSpanOption: {
          footerCellSpan: this.footerCellSpan,
        },
        weChatTitle:'报表',
        virtualScrollOption: {
        // 是否开启
            enable: true,
        },
        rowStyleOption: {
          stripe: true,
        },
        cellStyleOption: {
          headerCellClass: ({ column, rowIndex }) => {
            if (rowIndex === 0) {
              return "table-header-cell-class";
            }
          },

          footerCellClass: ({ row, column, rowIndex }) => {
            if (column.field == "rough_weight") {
                return "table-footer-cell-class1";
            }

            if (column.field == "tare") {
                return "table-footer-cell-class1";
            }

            if (column.field == "suttle") {
                return "table-footer-cell-class1";
            }

             if (column.field == "moisture") {
                return "table-footer-cell-class1";
            }

             if (column.field == "impurity") {
                return "table-footer-cell-class1";
            }

             if (column.field == "car_number") {
                return "table-footer-cell-class1";
            }

            if (column.field == "material") {
                return "table-footer-cell-class1";
            }

            if (column.field == "supplier") {
                return "table-footer-cell-class1";
            }
          },
        },
        columns: [
        //   {
        //     field: "",
        //     key: "1",
        //     title: "序号",
        //     align: "center",
        //     renderBodyCell: ({ row, column, rowIndex }, h) => {
        //       return ++rowIndex;
        //     },
        // },
        //   { field: "material",
        //     key: "2",
        //     title: "物料",
        //     align: "center",
        //   },
        //   { field: "supplier",
        //     key: "3",
        //     title: "供应商",
        //     align: "center"
        //   },
        //   { field: "rough_weight",
        //     key: "4",
        //     title: "毛重",
        //     align: "center"
        //   },
        //   { field: "tare",
        //     key: "5",
        //     title: "皮重",
        //     align: "center"
        //   },
        //   { field: "moisture", 
        //     key: "6",
        //     title: "扣水分",
        //     align: "center"
        //   },
        //   { field: "impurity",
        //     key: "7",
        //     title: "扣杂质",
        //     align: "center"
        //   },
        //   { field: "suttle",
        //     key: "8",
        //     title: "净重",
        //     align: "center"
        //   },
        //   { field: "car_number",
        //     key: "9",
        //     title: "车数",
        //     align: "center"
        //   },
        ],
        tableData: [
          // {
          //   materialname: "助磨剂",
          //   companyname: "眉山海螺新材料科技有限公司",
          //   gross_weight: "10.01",
          //   tare_weight: "7.88",
          //   buckle_water: "10.20",
          //   buckle_other: "3.90",
          //   net_weight: "2.22",
          //   vehiclecount: '12'
          // },
          // {
          //   materialname: "助磨剂",
          //   companyname: "眉山海螺新材料科技有限公司",
          //   gross_weight: "10.01",
          //   tare_weight: "7.88",
          //   buckle_water: "10.20",
          //   buckle_other: "3.90",
          //   net_weight: "2.22",
          //   vehiclecount: '12'
          // },
          // {
          //   materialname: "助磨剂",
          //   companyname: "眉山海螺新材料科技有限公司",
          //   gross_weight: "10.01",
          //   tare_weight: "7.88",
          //   buckle_water: "10.20",
          //   buckle_other: "3.90",
          //   net_weight: "2.22",
          //   vehiclecount: '12'
          // },
        ],
        footerData: [{
            // material: "合计",
            // supplier: "",
            // rough_weight: "44",
            // tare: "44",
            // suttle: "55",
            // moisture: "0",
            // impurity: "0",
            // car_number: "0",
        }],
        cus_id: '',
        tenantId: '',
        token: '',
        reportType: ''
      };
    },

    created () {
      this.cus_id = localStorage.getItem('cid')
      this.tenantId = localStorage.getItem('tenantId')
      this.token = localStorage.getItem('token')
      this.reportType = this.$route.query.reportType
      this.getTableHeader()
      this.tableData = JSON.parse(localStorage.getItem('reportTable')) || []
    },
    
    destroyed () {
      console.log('leave')
      localStorage.removeItem('reportTable')
    },

    methods: {
      async getTableHeader () {
        // const { data: res } = await this.$http.get('/daemon/api/report/columns?reportType=QUERT_XS_REPORTSUM')
        const { data: res } = await this.$http.get('/daemon/api/report/columns?reportType=' + this.reportType)
        console.log(res)
        if (res.code == 0) {
          this.weChatTitle = res.data.reportName
          this.columns = res.data.colums
        }
      },
      footerCellSpan({ row, column, rowIndex }) {
        if (column.field === "material") {
            return {
              rowspan: 1,
              colspan: 2,
            };
          }
          // does not need to be rendered
          else if (column.field === "supplier") {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }  
      },
      search () {
        const query = {
          tid: this.tenantId,
          token: this.token,
          cid: this.cus_id
        }
        if (this.reportType == 'QUERT_YL_REPORTDTL') {
          this.$router.push({ path: '/dssearch', query: query })
        } else if (this.reportType == 'QUERT_YL_REPORTSUM') {
          this.$router.push({ path: '/dssearch', query: query })
        } else if (this.reportType == 'QUERT_XS_REPORTDTL') {
          this.$router.push({ path: '/pssearch', query: query })
        } else if (this.reportType == 'QUERT_XS_REPORTSUM') {
          this.$router.push({ path: '/psssearch', query: query })
        } 
      }
    }
}
</script>

<style lang="less" scoped>
.report_box {
  width: 100%;
}

.report {
    width: 16rem;
    padding-bottom: 2rem;
}

/deep/ .table-header-cell-class {
    background: #29afa5 !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class1 {
    background: #91d5ff !important;
    color: #fff !important;
  }

 /deep/ .table-footer-cell-class2 {
    background: orange !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class3 {
    background: #ff5457 !important;
    color: #fff !important;
  }

  /deep/ .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td, .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
    padding: 0;
  }

  /deep/ .van-button--large {
  width: 100%;
  // width: 7.5rem;
  height: .8rem;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: #29afa5;
  // border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}
</style>