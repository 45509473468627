<template>
  <div class="OrdererInfo">
    <div v-wechat-title='$route.meta.title'></div>
    <van-field readonly clickable label="开始日期：" :value="start_time" placeholder="" @click="showStartTime = true"/>
    <van-field readonly clickable label="结束日期：" :value="end_time" placeholder="" @click="showEndTime = true"/>
    <van-field readonly clickable label="供 应 商 ：" :value="supplier" placeholder="请选择供应商" @click="showSupplier = true"/>
    <van-field readonly clickable label="物料名称：" :value="material" placeholder="请选择物料" @click="showMaterial = true"/>
    <!-- 开始日期 -->
    <van-popup v-model="showStartTime" position="bottom">
    <van-datetime-picker
        type="date"
        v-model="currentDate"
        :formatter="formatter"
        @confirm="onStartConfirm"
        @cancel="showStartTime = false"
    />
    </van-popup>

    <!-- 结束日期 -->
    <van-popup v-model="showEndTime" position="bottom">
    <van-datetime-picker
        type="date"
        v-model="currentEndDate"
        :formatter="formatter"
        @confirm="onEndConfirm"
        @cancel="showEndTime = false"
    />
    </van-popup>

    <!-- 选择供应商 -->
    <van-popup v-model="showSupplier" position="bottom" >
      <div class="btn_box">
        <div @click="showSupplier = false">取消</div>
        <div style="color:#29afa5" @click="confirmSupplier">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_supplier" placeholder="输入供应商名称进行模糊搜索" :clearable=false />
        <div class="search_btn" @click="searchSupplier">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in supplierList" :key="item.id" @click="chooseSupplierList(item)" :class="{ active: item.id === chooseSupplier.id }" />
      </van-cell-group>
    </van-popup>

    <!-- 选择物料 -->
    <van-popup v-model="showMaterial" position="bottom" >
      <div class="btn_box">
        <div @click="showMaterial = false">取消</div>
        <div style="color:#29afa5" @click="confirmMaterial">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_material" placeholder="输入物料名称进行模糊搜索" :clearable=false />
        <div class="search_btn" @click="searchMaterial">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in materialList" :key="item.id" @click="chooseMaterialList(item)" :class="{ active: item.id === chooseMaterial.id }" />
      </van-cell-group>
    </van-popup>
    <van-button size="large" @click="search">查询</van-button>
  </div>
</template>

<script>
export default {
  name: 'SsSearch',
  data () {
    return {
        showStartTime: false,
        showEndTime: false,
        showSupplier: false,
        showMaterial: false,
        start_time: this.util.getUpDay(new Date().getTime()),
        end_time: this.util.getDate(new Date().getTime()),
        supplier: '',
        supplierId: '',
        search_supplier: '',
        material: '',
        materialId: '',
        search_material: '',
        currentDate: new Date(),
        currentEndDate: new Date(),
        supplierList: [],
        chooseSupplier: {},
        materialList: [],
        chooseMaterial: {},
        cus_id: ''
    }
  },
  created () {
    this.cus_id = localStorage.getItem('cid')
    this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() - 1))
    this.getSupplierList()
    this.getMateriaList()
  },
  methods: {
    async getSupplierList () {
      const { data: res } = await this.$http.get('/ymt/company/getListForApplet/3/' + this.cus_id + '/104/' + this.search_supplier,)
      console.log(res)
      if (res.code == 0) {
        this.supplierList = res.data || []
        this.supplierList.unshift({name: '暂不选择', id: ''})
        this.supplier = this.supplierList.length <= 0 ? '' : this.supplierList[0].name
        this.supplierList.forEach(item => {
          if (item.name === this.supplier) {
            this.chooseSupplier = item
            console.log(this.chooseSupplier)
          }
        })

        // if (this.supplierList !== null && this.supplierList.length > 0) {
        //     // this.clientId = this.cusOrdList[0].id
        //     // // 获取物料
            
        //   }
      }
    },

    async getMateriaList () {
      const type = '101'
      const { data: res } = await this.$http.post('/ymt/material/options/' + this.search_material, { type: type})
      console.log(res)
      if (res.code == 0) {
        this.materialList = res.data || []
        this.materialList.unshift({name: '暂不选择', id: ''})
        this.material = this.materialList.length <= 0 ? '' : this.materialList[0].name
        this.materialId = this.materialList.length <= 0 ? '' : this.materialList[0].id
        this.materialList.forEach(item => {
          if (item.name === this.material) {
            this.chooseMaterial = item
          }
        })
      }
    },

    // 开始
    onStartConfirm (value) {
      this.showStartTime = false
      const time = new Date(this.currentDate).toLocaleDateString().split('/')
      if (Number(time[1]) < 10) { time[1] = '0' + time[1] }
      if (Number(time[2]) < 10) { time[2] = '0' + time[2] }
      this.start_time = time.join('-')
    },

    // 结束
    onEndConfirm () {
      this.showEndTime = false
      const time = new Date(this.currentEndDate).toLocaleDateString().split('/')
      if (Number(time[1]) < 10) { time[1] = '0' + time[1] }
      if (Number(time[2]) < 10) { time[2] = '0' + time[2] }
      this.end_time = time.join('-')
    },

    // 选择供应商
    searchSupplier () {
      this.getSupplierList()
    },

    chooseSupplierList (val) {
      this.chooseSupplier = val
    },

    confirmSupplier () {
      this.supplier = this.chooseSupplier.name
      this.supplierId = this.chooseSupplier.id
      this.showSupplier = false
    },

    // 选择物料
    searchMaterial () {
      this.getMateriaList()
    },
    
    chooseMaterialList (val) {
      this.chooseMaterial = val
    },

    confirmMaterial () {
      this.material = this.chooseMaterial.name
      this.materialId = this.chooseMaterial.id
      this.showMaterial = false
    },

    async search () {
      this.$loading.show({ tip: '正在查询中' })
      var supplierArr = []
      if (this.supplierId == '') {
        this.supplierList.filter(x => {
          if (x.id != '') {
            supplierArr.push(x.id)
          }
        })
      }
      const { data: res } = await this.$http.post('/daemon/api/report/query', {
        set_date: [this.start_time, this.end_time],
        supplier: this.supplierId == '' ? supplierArr : [this.supplierId],
        cloud_id: this.materialId,
        reportType: 'QUERT_YL_REPORTSUM'
      })
      console.log(res)
      setTimeout(() => {
        if (res.code == 0) {
          this.$loading.hide()
          localStorage.setItem('reportTable', JSON.stringify(res.data))
          this.$router.go(-1)
        } else {
          this.$loading.hide()
          this.$toast({
            message: '查询接口报错',
            duration: 1000
          })
        }
      }, 1000)
      
    },

    // 日期格式
    formatter (type, val) {
      if (type === 'year') {
        this.value1 = val
        return val + '年'
      }
      if (type === 'month') {
        this.value2 = val
        return val + '月'
      }
      if (type === 'day') {
        this.value3 = val
        return val + '日'
      }
      return val
    },
  }
}
</script>

<style lang="less" scoped>
.OrdererInfo {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: .1rem;
//   background-color: #f7f7f7;
}

.orderer_info {
  padding: .1rem .16rem;
  border-bottom: 1px solid #e1e1e1;
  color:#999;
}

.van-cell {
  height: .5rem;
  line-height: .5rem;
  padding-left: .2rem;
  border-bottom: 1px solid #e1e1e1;
  font-size: .14rem;
  padding: 0 .16rem;
}

/deep/ .van-field__label {
  width: .88rem;
  margin-right: 0;
//   text-align-last:justify;
//   text-align:justify;
//   text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
}

/deep/ .van-picker__cancel {
  font-size: .16rem;
}

/deep/ .van-picker__confirm {
  font-size: .16rem;
  color: #29afa5;
}

/deep/ .van-field__control--custom {
  min-height: .5rem;
}

/deep/ .van-radio--horizontal {
    margin-right: .2rem;
}

/deep/ .van-button--large {
  width: 95%;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: linear-gradient(to right, #4bb0ff, #6149f6);
  border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.btn_box {
  display: flex;
  justify-content: space-between;
  padding: .1rem .16rem;
  font-size: .16rem;
  color: #969799;
  border-bottom: 1px solid #D7D6DC;
}

/deep/ .van-search {
  width: 3.22rem;
}

/deep/ .van-search .van-cell {
  height: .4rem;
  line-height: .4rem;
  border-bottom:none;
  padding-left: 0;
}

/deep/ .van-search__content {
  background-color: #fff;
  border: .01rem solid #E6E6EA;
  border-radius: .2rem;
}

.search_btn {
  line-height: .62rem;
  font-size: .16rem;
  color: #29afa5;
}

/deep/ .van-search .van-field__control {
  line-height: .4rem;
  font-size: .14rem;
}

/deep/ .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
  font-size: .16rem;
  color: #969799;
}

/deep/ .van-cell-group {
  height: 2rem;
  overflow: scroll;
  font-size: .16rem;
  text-align: center;
  border: none;
  border-top: 1px solid #D7D6DC;
  padding: .1rem 0;
  .van-cell {
    border-bottom: none;
    padding: 0;
    height: .45rem;
    line-height: .45rem;
    color: #999;
    font-weight: 700;
  }
}

</style>