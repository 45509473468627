<template>
  <div class="execution">

    <div class="page-box">
      <div class="grid-content">
        <div class="grid-content-head">
          <span class="line bg-success"></span><span class="font-primary">{{ loadQueueList.length > 0 && !!loadQueueList[0].roadId ? loadQueueList[0].roadName : '待分配车道'}}</span>
        </div>
        <div class="ml10">
          <div class="truck-row">
            <div class="truck-status">
              <div
                :style="(Object.keys(currentLoadInfo).length > 0 ? 'background-color: red;' : 'background-color:green;') + 'color: white;text-align: center;font-weight: bold;font-size: 15pt;width: 1rem;'">
                {{ Object.keys(currentLoadInfo).length > 0 ? "装车中" : "等待中" }}</div>
            </div>
            <div class="truck-number">
              {{ (Object.keys(currentLoadInfo).length > 0 ? currentLoadInfo.vehicle : '') }}
            </div>
          </div>
        </div>
      </div>
      <div class="grid-content bm-h">
        <div class="grid-content-head">
          <span class="line bg-warning"></span><span class="font-warning">排队信息</span>
        </div>
        <div :class="'grid-content-item' + (item.orderId == currentOrderId ? ' grid-content-item-select' : '')"
          v-for="(item, index) in loadQueueList" :key="index">
          <div class="grid-content-item-num">
            {{ item.number }}
          </div>
          <div class="grid-content-item-vehicle">
            {{ item.vehicle }}
          </div>
          <div class="grid-content-item-state">
            <div :color="item.queueStatus == '0' ? 'green' : 'red'"> {{!item.roadId?"待分配":item.queueStatusName }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "loadLine",
  data() {
    return {
      loadQueueList: [],
      tableLoading: false,
      currentLoadInfo: {},
      loadType: "101",
      currentOrderId: 2,
      tenantId: 1,
      number: 0,
      dev_id: "",
    };
  },
  computed: {},

  watch: {},
  activated() { },
  created() {
    this.currentOrderId = this.$route.query.orderId;
    this.tenantId = this.$route.query.tid;
    this.dev_id = localStorage.getItem("cid");
    console.log("获取到订单信息、", this.orderId);
    console.log("获取到司机信息、", this.dev_id);
    console.log("获取到租户信息", this.tenantId);
    this.getQueueList();
    setInterval(() => {
      this.getQueueList();
    }, 60000);
  },

  deactivated() {
    console.log("关闭页面");
  },
  methods: {
    // 获取排队信息列表
    async getQueueList() {
      this.tableLoading = true;
      const { data: res } = await this.$http.post(
        "/ymt/companyinterface/interface/forwarding/" + this.tenantId + "/RQL",
        {
          orderId: this.currentOrderId,
          tenantId: this.tenantId,
        }
      );
      console.log(res);
      this.tableLoading = false;
      if (res.code == 0) {
        this.loadQueueList = res.data;
        this.number = res.msg;
        if (this.loadQueueList.length == 0) {
          this.$dialog({
            message: '没有排队信息',
            theme: 'round-button'
          }).then(() => {
            this.btnLoading = false
            console.log('没有排队信息')
            wx.miniProgram.navigateTo({
              url: '/pages/orderList/orderList'
            })
          })
        }
        var index = this.loadQueueList.findIndex(function (queue) { return queue.queueStatus == '1' });
        if (index > -1) {
          this.currentLoadInfo = this.loadQueueList[index];
        }
        console.log("排队信息", this.loadQueueList);
      } else {
        this.$dialog({
          message: res.msg,
          theme: 'round-button'
        }).then(() => {
          this.btnLoading = false
          console.log('没有排队信息')
          wx.miniProgram.navigateTo({
            url: '/pages/orderList/orderList'
          })
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dddd {
  display: block;
  visibility: hidden;
  width: 1px;
  height: 0px;
}

/deep/.checkItem {
  background-color: red;
}

/deep/.fab-main-container {
  position: absolute !important;
  right: 50px !important;
  z-index: 9999;
  top: 0px !important;
  overflow: initial;
  box-sizing: border-box;
}

.page-box {
  width: 100%;
  height: 100%;

  /deep/.grid-content {
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    background-color: #f8f8f8;
    padding: 5px;
    font-size: 16px;
    height: 100%;
    line-height: 36px;
    box-shadow: 0 5px 6px 0 #e6e6e6;
    overflow: hidden;

    .grid-content-head {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

      .el-button {
        background-color: #d10808;
        color: #fff;
      }

      .fab {
        background-color: #09be5a !important;
      }
    }

    .grid-content-item {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      margin: 0 5px 10px 5px;
      border-radius: 30px;
      border: 1px solid #e6e6e6;

      .grid-content-item-num {
        color: chocolate;
        font-size: 20pt;
        font-weight: bold;
        padding-left: 20px;
      }

      .grid-content-item-vehicle {
        color: rgb(4, 71, 14);
        font-size: 13pt;
        font-weight: bold;
        padding-right: 20px;
      }

      .grid-content-item-state {
        font-size: 13pt;
        font-weight: bold;
        padding-right: 20px;
      }
    }

    .grid-content-item-select {
      border: 3px solid green;
    }

    .truck-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 30px;
      line-height: 30px;
    }

    .truck-status {
      // flex: 1;
      width: 100%; //自己调整
      min-height: 30px;
      font-size: 13px;
      color: #29afa5;
    }

    .truck-number {
      // flex: 1;
      width: 100%; //自己调整
      min-height: 30px;
      font-size: 28px;
      color: #ef1111;
      font-weight: bold;
    }
  }

  /deep/ .grid-queue {
    overflow: visible !important;
  }

  .bm-h {
    height: 560px;
    margin-top: 10px;
  }
}

/deep/ .el-table thead {
  color: #909399;
  font-weight: 100 !important;
  line-height: 20px !important;
}

/deep/ .el-col {
  margin-bottom: 20px;
}

/deep/ .el-card__body {
  padding-bottom: 0;
  min-height: 500px;
  height: 100%;
}

.right-content {
  height: 648px;
  overflow-y: auto;
}

.right-cell {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  line-height: 30px;
}

.line {
  display: inline-block;
  width: 10px;
  height: 23px;
  background-color: #409eff;
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 6px;
}

.bg-warning {
  background-color: #e6a23c !important;
}

.bg-success {
  background-color: #29afa5 !important;
}

.font-primary {
  color: #409eff !important;
  font-size: 17pt;
}

.font-warning {
  color: #e6a23c !important;
  font-size: 15pt;
}

.font-success {
  color: #29afa5 !important;
}

.ml10 {
  width: 100%;
  margin-left: 1px;
}

/deep/.transfer {
  height: 90%;
  padding: 0 20px;

  .el-transfer__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .el-transfer__buttons .el-button {
    margin: 5px 0;
  }

  .el-transfer-panel__body {
    height: 100% !important;
  }

  .el-transfer-panel {
    min-width: 150px;
    width: 36%;
  }
}

/deep/ .el-drawer__header {
  font-size: 16px;
  color: #303133;
  padding: 16px 10px 16px 24px;
  border-bottom: 1px solid #f0f0f0;
}

/deep/ .demo-drawer__footer {
  width: 100%;
  text-align: right;
  padding-right: 30px;

  .el-button {
    width: 18%;
    margin-top: 30px;
  }
}

/deep/ .icon-guanbishixin {
  font-size: 18px !important;
}
</style>
