<template>
    <div class="wechat-msg">
        <div v-wechat-title='$route.meta.title'></div>
        <div class="date">
            <span class="label">统计日期:</span>
            <span style="color: #29afa5"> {{saleDate}}</span>
        </div>
        <div class="content-box">
            <div class="label">统计内容:</div>
            <div class="content">
                {{content}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WechatMsgDetail',
    data () {
        return {
            saleDate: '',
            content: ''
        }
    },

    created () {
        this.saleDate = this.$route.query.saleDate
        this.content = this.$route.query.content
    }
}
</script>

<style lang="less" scoped>
.wechat-msg {
    font-size: .16rem;
    padding: .2rem .1rem;
}

.date {
    margin-bottom: .08rem;
    .label {
        width: 20%;
    }
}

.content-box {
    width: 100%;
    display: flex;
    .label {
        width: 20%;
    }
    .content {
        width: 80%;
        line-height: .26rem;
        background-color: #f7f2ec;
        padding: 0.02rem 0.08rem 0.08rem;
        color: purple;
    }
}

</style>