<template>
  <div style="width:100%;padding:20px">
    <div style="width:100%;display:flex;flex-flow:wrap">
    <div v-for="(item, index) in table" :key="index" class="box" :style="{'width': (table.length % 2 == 1 && table.length - 1 == index) ? '100%' : '50%', 'margin-right': (table.length % 2 == 1 && table.length - 1 == index) ? '1px' : '-1px'}">
      <div style="width: 300px;text-align:center;background:#ecf0f1;border-right:1px solid #464552;font-weight:700;">{{item.name || "zs"}}</div>
      <div style="padding:0 20px;font-weight:700;">{{item.value || 'haha'}}</div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "demo",
  data() {
    return {
      table: [{
        name: '客户名称',
        value: '阿里巴巴有限公司'
      },{
        name: '法定代表人',
        value: '周扬'
      },{
        name: '客户经理姓名',
        value: '杨曦'
      },{
        name: '客户经理编号',
        value: '300021568'
      },{
        name: '集团客户标识',
        value: '普通客户'
      },{
        name: '托管方式',
        value: '企业管理'
      },{
        name: '扣费账号',
        value: '1233515648'
      }]
    };
  },
};
</script>

<style lang="less" scoped>
.box {
  display:flex;
  width:50%;
  border:1px solid #464552;
  margin-top:-1px;
  height:60px;
  line-height:60px;
  // box-sizing:border-box
}
</style>