<template>
    <div class="pay">
        <div v-wechat-title='$route.meta.title'></div>
        <van-button size="large" loading-text="加载中..." :loading="btnLoading" type="info" @click="pay" class="pay_btn">支付</van-button>
    </div>
</template>

<script>
export default {
    name: 'pay',
    data () {
        return {
            orderId: '',
            tenantId: '',
            btnLoading: false
        }
    },
    created () {
        console.log(this.$route.query)
        this.orderId = this.$route.query.orderId
        this.tenantId = this.$route.query.tenantId
    },
    methods: {
        async pay () {
            this.btnLoading = true
            const { data: res } = await this.$http.get(`/ymt/vehicle/order/pay/getOrderPayInfo/${this.orderId}/${this.tenantId}`)
            console.log(res)
            this.btnLoading = false
            if (res.code == 1 || res.data == '') {
                return this.$toast({
                    message: '获取支付信息异常',
                    duration: 1000
                })
            } 

            window.location.href = res.data
        }
    }
}
</script>

<style lang="less" scoped>
.pay {
    text-align: center;
}

.pay_btn {
    width: 90%;
    margin-top: .2rem;
    border-radius: .3rem;
}

</style>