<template>
  <div class="selfCar">
    <div v-wechat-title='$route.meta.title'></div>

    <EZUIKitJs />
    <div class="vehicle-box">
      <div class="vehicle-box-title">车辆信息</div>
      <div class="vehicle-box-info">
        <div class="vehicle">{{vehicle}}</div>
        <div>
          <van-tag type="success" size="large">手工装车</van-tag>
        </div>
      </div>
    </div>

    <div class="car-info-box">
      <div class="fx-btn">
        <div class="ss-btn all-btn" :class="{active1: isActive == 1}" @touchstart="riseStart" @touchend="riseEnd">
          <div class="triangle"></div>
        </div>
      </div>
      <div class="middle-btn">
        <div class="qd-btn all-btn" :class="{active1: isActive2 == 1}" @click="start">
          <van-icon size="24" class="btn-icon" name="play-circle-o" /><span style="vertical-align: middle;">启动</span>
        </div>
        <div class="tz-btn all-btn" :class="{active1: isActive2 == 2}" @click="stop">
          <van-icon size="24" class="btn-icon" name="stop-circle-o" /><span style="vertical-align: middle;">停止</span>
        </div>
      </div>
      <div class="fx-btn">
        <div class="xj-btn all-btn" :class="{active1: isActive == 2}" @touchstart="dropStart" @touchend="dropEnd">
          <div class="triangle1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EZUIKitJs from "../../components/EZUIKitJs.vue";
export default {
  name: "selfCarSp",
  components: {
    EZUIKitJs,
  },
  data() {
    return {
      isActive: 0,
      isActive2: 0,
      roadData: {},
      vehicle: "",
    };
  },

  created() {
    this.roadData = JSON.parse(sessionStorage.getItem("roadData"));
    this.setWebsocket(this.roadData.webSocket);
  },

  mounted() {
    //防止页面后退
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
  },

  destroyed() {
    console.log("离开页面");
    this.socketApi.webSocketClose();
  },

  methods: {
    //建立socket连接
    setWebsocket(url) {
      const websocketIP = "ws://" + url;
      this.socketApi.initWebSocket(websocketIP).then((res) => {
        console.log(res, "res");
      });
      this.websocketSend({});
    },
    // 接收socket回调函数返回数据的方法
    getConfigResult(res) {
      console.log(res, "硬件数据"); //硬件返回的数据
      if (res.code == 0) {
        this.$toast({
          message: res.msg,
          duration: 2000,
        });
      } else if (res.code == 1) {
        this.$toast({
          message: res.msg,
          duration: 2000,
        });
      } else if (Object.prototype.toString.call(res) == "[object Array]") {
        let obj = res.find((x) => x.place == this.roadData.placeId);
        console.log(obj);
        this.vehicle = obj.vehicle;
      } else if (Object.prototype.toString.call(res) == "[object Object]") {
        this.vehicle = res.vehicle;
      } else {
        this.socketApi.webSocketClose();
      }
    },

    websocketSend(data) {
      //data为要发送的数据，this.getConfigResult为回调函数，用于在此页面接收socket返回的数据。
      this.socketApi.sendSock(data, this.getConfigResult);
    },

    riseStart() {
      this.isActive = 1;
      let data = {
        place: this.roadData.placeId,
        type: "up",
        info: "1"
      };
      this.websocketSend(data);
    },
    riseEnd() {
      this.isActive = 0;
      let data = {
        place: this.roadData.placeId,
        type: "up",
        info: "0"
      };
      this.websocketSend(data);
    },
    dropStart() {
      this.isActive = 2;
      let data = {
        place: this.roadData.placeId,
        type: "down",
        info: "1"
      };
      this.websocketSend(data);
    },
    dropEnd() {
      this.isActive = 0;
      let data = {
        place: this.roadData.placeId,
        type: "down",
        info: "0"
      };
      this.websocketSend(data);
    },
    start() {
      this.isActive2 = 1;
      let data = {
        place: this.roadData.placeId,
        type: "start",
      };
      this.websocketSend(data);
    },
    stop() {
      this.isActive2 = 2;
      let data = {
        place: this.roadData.placeId,
        type: "stop",
      };
      this.websocketSend(data);
    },

    touchEnd() {
      //手指离开
      console.log(1);
    },
    touchStart() {
      //手指触摸
      console.log(1222);
    },
  },
};
</script>


<style lang="less" scoped>
* {
  -webkit-touch-callout:none; /*系统默认菜单被禁用*/
  -webkit-user-select:none; /*webkit浏览器*/
  -khtml-user-select:none; /*早期浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none; /*IE10*/
  user-select:none;
}
.selfCar {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  .vehicle-box {
    margin: 0.1rem;
    box-shadow: 0 0.1rem 0.2rem 0 #e6e6e6;

    .vehicle {
      font-weight: bold;
      font-size: 0.16rem;
    }
  }

  .vehicle-box-info {
    display: flex;
    justify-content: space-between;
    border: 0.01rem solid #dde3f1;
    height: 0.4rem;
    line-height: 0.4rem;
    padding: 0 0.15rem;
    background-color: #fff;
  }

  .vehicle-box-title {
    height: 0.32rem;
    line-height: 0.32rem;
    background-color: #3a3f56;
    color: #fff;
    font-weight: bold;
    padding-left: 0.1rem;
  }

  .car-info-box {
    margin: 0.1rem 0.1rem;
    // border: .01rem solid #e6e6e6;
    padding: 0.2rem 0.15rem;
    background-color: #3a3f56;
    color: #fff;
    // box-shadow: 0 .1rem .2rem 0 #999595;
  }
}

.middle-btn {
  // width: 75%;
  display: flex;
  margin: 0.2rem auto;
  justify-content: space-between;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 0.2rem solid transparent;
  border-right: 0.15rem solid transparent;
  border-bottom: 0.2rem solid #fff;
  border-left: 0.15rem solid transparent;
  margin: 0 auto;
  margin-top: -0.1rem;
  border-radius: 0.03rem;
}

.triangle1 {
  width: 0;
  height: 0;
  border-top: 0.2rem solid #fff;
  border-right: 0.15rem solid transparent;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.15rem solid transparent;
  margin: 0 auto;
  margin-top: 0.15rem;
  border-radius: 0.03rem;
}

.all-btn {
  width: 0.48rem;
  height: 0.48rem;
  line-height: 0.32rem;
  font-size: 0.16rem;
  background-color: #1989fa;
  color: #fff;
  margin: auto;
  border-radius: 0.48rem;
  text-align: center;
  box-shadow: 0 0.1rem 0.2rem 0 #1d2136;
}

.ss-btn {
  background-color: #1989fa;
  border: 1px solid #1989fa;
}

.xj-btn {
  background-color: #ff976a;
  border: 1px solid #ff976a;
}

.qd-btn {
  width: 0.8rem;
  height: 0.4rem;
  // line-height: .4rem;
  padding-top: 0.02rem;
  background-color: #07c160;
  border: 1px solid #07c160;
}

.tz-btn {
  width: 0.8rem;
  height: 0.4rem;
  // line-height: .4rem;
  padding-top: 0.02rem;
  background-color: #ee0a24;
  border: 1px solid #ee0a24;
}

.active1 {
  opacity: 0.8 !important;
  box-shadow: none;
}



.btn-icon {
  vertical-align: middle;
  margin-right: 0.05rem;
}

</style>