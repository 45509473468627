<template>
    <div class="report_box">
      <div class="report">
        <div v-wechat-title='$route.meta.title'></div>
        <ve-table
          fixed-header
          border-y
          :columns="columns"
          :table-data="tableData"
          row-key-field-name="rowKey"
          :cell-style-option="cellStyleOption"
          :row-style-option="rowStyleOption"
          :footer-data="footerData"
          :cell-span-option="cellSpanOption"
        />
    </div>
    <van-button size="large" @click="search">
      <ve-icon name="filter" :size='48' class="" />
    </van-button>
    </div>
</template>

<script>
export default {
    name: 'ProcurementSchedule',
    data() {
      return {
        cellSpanOption: {
          footerCellSpan: this.footerCellSpan,
        },
        virtualScrollOption: {
        // 是否开启
            enable: true,
        },
        rowStyleOption: {
          stripe: true,
        },
        cellStyleOption: {
          headerCellClass: ({ column, rowIndex }) => {
            if (rowIndex === 0) {
              return "table-header-cell-class";
            }
          },

          footerCellClass: ({ row, column, rowIndex }) => {
            if (column.field == "pounds_code") {
                return "table-footer-cell-class1";
            }

            if (column.field == "rough_weight") {
                return "table-footer-cell-class1";
            }

            if (column.field == "tare") {
                return "table-footer-cell-class1";
            }

            if (column.field == "suttle") {
                return "table-footer-cell-class1";
            }

            if (column.field == "moisture") {
                return "table-footer-cell-class1";
            }

            if (column.field == "impurity") {
                return "table-footer-cell-class1";
            }

            if (column.field == "delivery_time") {
                return "table-footer-cell-class1";
            }

            if (column.field == "emptying_point") {
                return "table-footer-cell-class1";
            }

            if (column.field == "weighman") {
                return "table-footer-cell-class1";
            }
          },
        },
        columns: [
          {
            field: "",
            key: "1",
            title: "序号",
            align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return ++rowIndex;
            },
        },
          { field: "pounds_code",
            key: "2",
            title: "磅单编号",
            align: "center",
          },
          { field: "factory_time",
            key: "3",
            title: "进厂日期",
            align: "center",
          },
          { field: "material",
            key: "4",
            title: "物料名称",
            align: "center",
          },
          { field: "supplier",
            key: "5",
            title: "供应商",
            align: "center"
          },
          { field: "wagon_number",
            key: "6",
            title: "车号",
            align: "center"
          },
          { field: "rough_weight",
            key: "7",
            title: "毛重",
            align: "center"
          },
          { field: "tare",
            key: "8",
            title: "皮重",
            align: "center"
          },
          { field: "suttle",
            key: "9",
            title: "净重",
            align: "center"
          },
          { field: "moisture", 
            key: "10",
            title: "扣水分",
            align: "center"
          },
          { field: "impurity",
            key: "11",
            title: "扣杂质",
            align: "center"
          },
          { field: "delivery_time",
            key: "12",
            title: "出厂时间",
            align: "center"
          },
          { field: "emptying_point",
            key: "13",
            title: "卸料地点",
            align: "center"
          },
          { field: "weighman",
            key: "14",
            title: "司磅员",
            align: "center"
          },
        ],
        tableData: [
          {
            pounds_code: "13J776342222123",
            factory_time: "2021-05-20 12:00",
            material: "助磨剂",
            supplier: "眉山海螺新材料科技有限公司",
            wagon_number: "浙AB66666",
            rough_weight: "10.01",
            tare: "7.88",
            suttle: "10.20",
            moisture: "3.90",
            impurity: "2.22",
            delivery_time: "2021-06-20 12:00",
            emptying_point: "原料堆棚",
            weighman: "一卡通用户",
          },
          {
            pounds_code: "13J776342222123",
            factory_time: "2021-05-20 12:00",
            material: "助磨剂",
            supplier: "眉山海螺新材料科技有限公司",
            wagon_number: "浙AB66666",
            rough_weight: "10.01",
            tare: "7.88",
            suttle: "10.20",
            moisture: "3.90",
            impurity: "2.22",
            delivery_time: "2021-06-20 12:00",
            emptying_point: "原料堆棚",
            weighman: "一卡通用户",
          },
          {
            pounds_code: "13J776342222123",
            factory_time: "2021-05-20 12:00",
            material: "助磨剂",
            supplier: "眉山海螺新材料科技有限公司",
            wagon_number: "浙AB66666",
            rough_weight: "10.01",
            tare: "7.88",
            suttle: "10.20",
            moisture: "3.90",
            impurity: "2.22",
            delivery_time: "2021-06-20 12:00",
            emptying_point: "原料堆棚",
            weighman: "一卡通用户",
          },
          {
            pounds_code: "13J776342222123",
            factory_time: "2021-05-20 12:00",
            material: "助磨剂",
            supplier: "眉山海螺新材料科技有限公司",
            wagon_number: "浙AB66666",
            rough_weight: "10.01",
            tare: "7.88",
            suttle: "10.20",
            moisture: "3.90",
            impurity: "2.22",
            delivery_time: "2021-06-20 12:00",
            emptying_point: "原料堆棚",
            weighman: "一卡通用户",
          },
          {
            pounds_code: "13J776342222123",
            factory_time: "2021-05-20 12:00",
            material: "助磨剂",
            supplier: "眉山海螺新材料科技有限公司",
            wagon_number: "浙AB66666",
            rough_weight: "10.01",
            tare: "7.88",
            suttle: "10.20",
            moisture: "3.90",
            impurity: "2.22",
            delivery_time: "2021-06-20 12:00",
            emptying_point: "原料堆棚",
            weighman: "一卡通用户",
          },
        ],
        footerData: [{
            pounds_code: "小计",
            factory_time: "",
            material: "",
            supplier: "",
            wagon_number: "",
            rough_weight: "101.01",
            tare: "7.18",
            suttle: "10.20",
            moisture: "",
            impurity: "",
            delivery_time: "",
            emptying_point: "",
            weighman: "",
        }],
        cus_id: '',
        tenantId: '',
        token: ''
      };
    },

    created () {
      this.cus_id = localStorage.getItem('cid')
      this.tenantId = localStorage.getItem('tenantId')
      this.token = localStorage.getItem('token')
    },

    methods: {

      footerCellSpan({ row, column, rowIndex }) {
        if (column.field === "pounds_code") {
            return {
              rowspan: 1,
              colspan: 5,
            };
          }
          // does not need to be rendered
          else if (column.field === "factory_time") {
            return {
              rowspan: 0,
              colspan: 0,
            };
          } else if (column.field === "material") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        } else if (column.field === "supplier") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        }  else if (column.field === "wagon_number") {
            return {
              rowspan: 0,
              colspan: 0,
            }
        }  
      },
      search () {
        this.$router.push({ path: '/dssearch', query: { tid: this.tenantId, token: this.token, cid: this.cus_id } })
      }
    }
}
</script>

<style lang="less" scoped>
.report_box {
  width: 100%;
}

.report {
    width: 16rem;
    padding-bottom: 2rem;
}

/deep/ .table-header-cell-class {
    background: #29afa5 !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class1 {
    background: #91d5ff !important;
    color: #fff !important;
  }

 /deep/ .table-footer-cell-class2 {
    background: orange !important;
    color: #fff !important;
  }

  /deep/ .table-footer-cell-class3 {
    background: #ff5457 !important;
    color: #fff !important;
  }

  /deep/ .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td, .ve-table table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
    padding: 0;
  }

  /deep/ .van-button--large {
  width: 100%;
  // width: 7.5rem;
  height: .8rem;
  // margin-top: .5rem;
  // margin-bottom: .2rem;
  // margin-right: .1rem;
  // margin-left: .1rem;
  background: #29afa5;
  // border-radius: .2rem;
  color: #fff;
  position: fixed;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

// .search_btn {
//   position: fixed;
//   bottom: 3rem;
//   right: .5rem;
// }

</style>